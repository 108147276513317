import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { map, filter } from "rxjs/operators";
import { UntilDestroy } from "@ngneat/until-destroy";

import { environment } from "@environments/environment";

import { UserData } from "@interfaces/user";
import { RefreshToken } from "@interfaces/refresh-token";
import { JwtService } from "../jwt.service";
import { RedirectService } from "../redirect.service";
import { GenericResp } from "@interfaces/generic-resp";
import { RolesService } from "../roles.service";
import { ModuleAccessService } from "@services/module-access.service";
import { ModulesAccess, Settings } from "@interfaces/company";

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private readonly currentUserSubj: BehaviorSubject<UserData> =
    new BehaviorSubject<UserData>({} as UserData);
  private isRefreshingUser = false;

  readonly isLogInSubj = new BehaviorSubject<boolean>(true);
  readonly currentUser$ = this.currentUserSubj
    .asObservable()
    .pipe(filter((user: UserData | null) => !!user));
  readonly isLogIn$: Observable<boolean> = this.isLogInSubj.asObservable();

  // Register subscriptions to unsubscribe on destroy
  isLogInSubscription!: Subscription;
  refreshJwtSubscription!: Subscription;
  refreshTokenSubscription!: Subscription;
  refreshUserDataSubscription!: Subscription;

  constructor(
    private readonly http: HttpClient,
    private readonly jwtService: JwtService,
    private readonly redirectService: RedirectService,
    private readonly rolesService: RolesService,
    private readonly moduleAccessService: ModuleAccessService
  ) {
    // this.init();
    this.isLogInSubscription = this.isLogIn$.subscribe((isLogIn) => {
      if (!isLogIn) {
        this.refreshUserData();
      }
    });
    this.refreshJwtSubscription = this.jwtService.refreshJwt$.subscribe(
      (refresh: boolean) => {
        if (refresh) {
          this.refreshTokenSubscription = this.refreshToken().subscribe(
            (resp: GenericResp<RefreshToken>) => {
              const newToken = resp.data.new_token;
              if (newToken) {
                this.currentUserData.token = newToken;
                this.jwtService.refreshedJwtSubj.next(newToken);
                console.log(
                  "[Http Error Interceptor]%c Token refreshed",
                  "color:green"
                );
              }
            }
          );
        }
      }
    );
  }

  get currentUserData(): UserData {
    return this.currentUserSubj.value;
  }

  set currentUserData(userData: UserData) {
    console.log("[Auth - Service] %cSetting user data", "color:blue");
    localStorage.setItem("currentUser", JSON.stringify(userData));
    this.jwtService.jwt = userData.token;
    this.currentUserSubj.next(userData);
  }

  get isLoggedIn(): boolean {
    return !!this.currentUserData;
  }

  // Get user data from localStorage if it exists and refresh it from the backend
  // If the user data does not exist in localStorage, redirect to SSO login
  init(): void {
    console.log("[Auth - Service] %cInitializing", "color:blue");
    if (!this.retrieveUserFromLocalStorage()) {
      this.redirectService.redirectSSOLogin();
    }
  }

  login(data: { code: string; state: string }) {
    return this.http
      .post<GenericResp<UserData>>(
        `${environment.backend.cyberlab}/api/login/validate`,
        data
      )
      .pipe(
        map((resp: GenericResp<UserData>) => {
          if (resp.success) {
            const userData = {
              user: resp.data.user,
              token: resp.data.token,
            };
            const user = userData.user;
            if (
              user.is_disabled ||
              !user.confirmed ||
              (user.company_id &&
                user.company_confirmation_status != "approved")
            ) {
              this.redirectToSSOErrorPage(user);
              localStorage.removeItem("currentUser");
              return;
            }

            this.currentUserData = userData;
            return userData;
          }
          return;
        })
      );
  }

  refreshToken(): Observable<GenericResp<RefreshToken>> {
    return this.http.get<GenericResp<RefreshToken>>(
      `${environment.backend.cyberlab}/api/auth/refreshToken`
    );
  }

  private refreshUserData() {
    if (!this.isRefreshingUser) {
      // Refresh user data only if userData exists in localStorage and auth service is not already refreshing user
      this.isRefreshingUser = true;
      this.refreshUserDataSubscription = this.http
        .get<GenericResp<UserData>>(
          `${environment.backend.cyberlab}/api/auth/getAuthenticatedUser`
        )
        .subscribe({
          next: (resp: any) => {
            // FIXME: Set a proper interface for the response
            if (resp.success) {
              const userData = {
                user: resp.data.user,
                token: resp.data.token,
              };
              const user = userData.user;

              if (
                user.is_disabled ||
                !user.confirmed ||
                (user.company_id &&
                  user.company_confirmation_status != "approved")
              ) {
                this.redirectToSSOErrorPage(user);
                localStorage.removeItem("currentUser");
                return;
              }

              this.currentUserData = userData;
              console.log(
                "[Auth - Service] %cUser data retrieved from server",
                "color:green",
                userData
              );
              // this.setModulesAccessData(userData.user.company.settings); // this for module access
              this.rolesService.currentUserRoles$.next(userData.user.role);
              console.log("userData.user.roles", userData.user.role);
            } else {
              console.log(resp);
            }
          },
        });

      this.refreshUserDataSubscription.add(
        () => (this.isRefreshingUser = false)
      );
    }
  }

  setModulesAccessData(settings: Settings) {
    const modulesAccess: ModulesAccess = {
      cyberlearn_access: settings.cyber_awareness_access,
      cyberlab_access: settings.cyberlab_access,
      cyberevent_access: settings.cyberevent_access,
      cyber_hiring_access: settings.cyber_hiring_access,
      cyber_awareness_access: settings.cyber_awareness_access,
      cyber_certification_access: settings.cyber_certification_access,
    };
    this.moduleAccessService.moduleAccess$.next(modulesAccess);
  }

  private retrieveUserFromLocalStorage(): boolean {
    const userDataJson = localStorage.getItem("currentUser");
    if (!userDataJson) {
      return false;
    }
    try {
      const userData = JSON.parse(userDataJson);
      console.log("userData", userData);
      if (userData) {
        this.currentUserData = userData;
        this.isLogInSubj.next(false);
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  private redirectToSSOErrorPage(user: any) {
    const ssoFrontUrl = environment.frontend.account;
    if (user.is_disabled) {
      window.location.href = ssoFrontUrl + "/account-issue/account-disabled";
      return;
    }
    if (!user.confirmed) {
      window.location.href =
        ssoFrontUrl + "/account-issue/account-not-confirmed";
      return;
    }
    if (user.company_id && user.company_confirmation_status != "approved") {
      window.location.href =
        ssoFrontUrl + "/account-issue/company-not-confirmed";
      return;
    }
  }

  logoutFromBackend(): Observable<boolean> {
    return this.http
      .post<GenericResp<boolean>>(
        `${environment.backend.cyberlab}/api/auth/users/logout`,
        {}
      )
      .pipe(
        map((resp: GenericResp<boolean>) => {
          localStorage.removeItem("currentUser");
          // this.currentUserSubj.next(null);

          return resp.data;
        })
      );
  }

  switchCompany(companyId: number) {
    return this.http.post(
      `${environment.backend.cyberlab}/api/manager/change-company`,
      { company_id: companyId }
    );
  }
}
