import {
  AfterViewInit,
  Component,
  inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { NavigationMenuComponent } from "@components/modals/navigation-menu/navigation-menu.component";
import { environment } from "@environments/environment";
import { PaginationResp } from "@interfaces/generic-pagination-resp";
import { GenericResp } from "@interfaces/generic-resp";
import { GeneralNotif, Notification } from "@interfaces/notification";
import { SideBarLinks } from "@interfaces/sidebar";
import { UserData, User } from "@interfaces/user";
import { DialogRef, DialogService } from "@ngneat/dialog";
import { UntilDestroy } from "@ngneat/until-destroy";

import { AuthService } from "@services/auth/auth.service";
import { CustomNgScrollbarService } from "@services/custom-ng-scrollbar.service";
import { MixpanelService } from "@services/mixpanel.service";
import { NavBarService } from "@services/nav-bar.service";
import { NotificationsService } from "@services/notifications.service";
import { RolesService } from "@services/roles.service";
import { NgScrollbar } from "ngx-scrollbar";
import { filter, Subscription } from "rxjs";
import { NotificationPopupComponent } from "./components/modals/notification-popup/notification-popup.component";
import { SubscriptionMenuComponent } from "@components/subscription-menu/subscription-menu.component";
import { OnboardingModalsComponent } from "@components/modals/onboarding-modals/onboarding-modals.component";
import { OnboardingModalContent } from "@interfaces/onboarding";
import { OnboardingService } from "@services/onboarding.service";
import { UtilitiesService } from "@services/utilities.service";
import { FeatureFlagsService } from "@services/feature-flags.service";

@UntilDestroy({ checkProperties: true })
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  private dialog: DialogService = inject(DialogService);
  @ViewChild("ngScrollbar") scrollbarRef: NgScrollbar | undefined;
  readonly document = document;

  // Register subscriptions to unsubscribe on destroy
  loginSubscription!: Subscription;
  queryParamsSubscription!: Subscription;
  currentUserSubscription!: Subscription;
  sidebarLinks: any = []; // Fix me to interface
  userRoles: string[] = [];
  showWalletNav = false;
  isUserLoaded = false;
  user: User | null | undefined = {} as User;
  isLoading = true;
  readonly backendAccount = environment.backend.account;
  readonly frontendAccount = environment.frontend.account;
  readonly environment = environment;
  scrollSubscription!: Subscription;

  showScrollButton = false;

  isMobileSidebarMode = false;
  notifications!: GeneralNotif[];
  notificationsLastPage = 1;
  isSecialNotificationsLoaded = false;
  activeAccordionItemIndex = 0;
  isOpen = false;

  notificationNumber = 0;

  specialNotifInterval!: any;
  showPremiumButton = false;
  // pathTranslations: any = {
  //   assistance_session_page: "/assistance/sessions/",
  //   lab_requests_page: "/company/labs/requests",
  //   certification_details_page: "/certifications/",
  //   certification_instance_page: "/certifications/",
  //   certification_request_page: "/certifications/certification-requests",
  // };

  onboardContentUser: OnboardingModalContent[] = [
    {
      id: 1,
      title: $localize`:@@appComponent.playOffensiveDefensiveAndAppSec:Play Offensive, Defensive and AppSec`,
      description: $localize`:@@appComponent.playOffensiveDefensiveAndAppSecDescription:Play Offensive, Defensive, and AppSec real-world scenarios in our labs and certifications. Whether you're a beginner or a professional, there's always something to challenge and inspire you!`,
    },
    {
      id: 2,
      title: $localize`:@@appComponent.participateToEvents:Participate in Events`,
      description: $localize`:@@appComponent.participateToEventsDescription:Put your skills to the test! Participate in CTF (Capture The Flag) events and compete with peers. A perfect opportunity to learn, compete, and shine.`,
    },
    {
      id: 3,
      title: $localize`:@@appComponent.getCertifiedAndFindYourPerfectJob:Get certified & find your perfect job`,
      description: $localize`:@@appComponent.getCertifiedAndFindYourPerfectJobDescription:Get our certifications like Jr Pentester, SOC Analyst, DevSecOps Specialist, and many more. Connect directly with potential employers through our Hiring Hub.`,
    },
    {
      id: 4,
      title: $localize`:@@appComponent.oneToOneCoachingFromExperts:One-to-one coaching from experts`,
      description: $localize`:@@appComponent.oneToOneCoachingFromExpertsDescription:You tried hard and still blocked? Request a one-to-one coaching from our team of cybersecurity experts. Get explanations and insights to accelerate your growth.`,
    },
  ];

  accountOnBoardContentUser: OnboardingModalContent[] = [
    {
      id: 1,
      title: $localize`:@@appComponent.welcomeToSecDojo:Welcome to SecDojo,`,
      description: $localize`:@@appComponent.welcomeToSecDojoDescription:Choose from a variety of tracks: Offensive, Defensive and AppSec. Learn in Cyberlearn, practice in Cyberlab, and grow your career with us with CTFs, certifications and our Hiring Hub!`,
    },
    {
      id: 2,
      title: $localize`:@@appComponent.youHave300CoinsInYourFreeWallet:You have 300 coins in your free wallet!`,
      description: $localize`:@@appComponent.youHave300CoinsInYourFreeWalletDescription:You can get started right away using your free wallet to purchase labs. Additionally, you can enroll in some courses for free!`,
    },
    {
      id: 3,
      title: $localize`:@@appComponent.earnMoreCoinsByCompletingLabs:Earn more coins by completing labs`,
      description: $localize`:@@appComponent.earnMoreCoinsByCompletingLabsDescription:Every time you complete a lab, you'll earn coins equivalent to 20% of your score, which you can use to access more labs.`,
    },
  ];

  accountOnBoardCompanyMemberContant: OnboardingModalContent[] = [
    {
      id: 1,
      title: $localize`:@@appComponent.welcomeToSecDojo:Welcome to SecDojo,`,
      description: $localize`:@@appComponent.welcomeToSecDojoDescription:Choose from a variety of tracks: Offensive, Defensive and AppSec. Learn in Cyberlearn, practice in Cyberlab, and grow your career with us with CTFs, certifications and our Hiring Hub!`,
    },
    {
      id: 2,
      title: $localize`:@@appComponent.howCanYouGetCoursesAndLabs:How can you get courses and labs?`,
      description: $localize`:@@appComponent.howCanYouGetCoursesAndLabsDescription:You can request the ones you're interested in, and your organization manager will grant you access. Alternatively, ask them for a wallet refill, to make purchases yourself.`,
    },
  ];

  // modulesAccess: ModulesAccess = {
  //   cyberlearn_access: 0,
  //   cyberlab_access: 0,
  //   cyberevent_access: 0,
  //   cyber_hiring_access: 0,
  //   cyber_awareness_access: 0,
  //   cyber_certification_access: 0,
  // };

  co_branding!: number;
  private onboardingTriggered = false;
  modulesAccess = {
    cyberlearnAccess: true,
    cyberlabAccess: true,
    eventAccess: true,
    cyberawarenessAccess: false,
    certificationAccess: false,
    hiringAccess: false,
    classroomAccess: false,
  };
  isConstructorExuted = false;
  constructor(
    private readonly authService: AuthService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly rolesService: RolesService,
    private readonly navBar: NavBarService,
    private readonly mixPanelService: MixpanelService,
    private readonly notificationService: NotificationsService,
    private readonly customScrollbarService: CustomNgScrollbarService,
    private readonly onboardingService: OnboardingService,
    private readonly utilitesService: UtilitiesService,
    private readonly featureFlagService: FeatureFlagsService
  ) {
    this.mixPanelService.init();
    this.authService.currentUser$.subscribe((user: UserData | null) => {
      this.user = user?.user;
      if (user?.user && user.user.company) {
        this.co_branding = user.user.company.settings.co_branding;
      }
      if (this.user) {
        if (!this.isConstructorExuted)
          this.featureFlagService.loadFeatureFlags();

        this.modulesAccess = this.utilitesService.getModulesAccess(this.user);
        this.isUserLoaded = true;

        if (!this.onboardingTriggered && this.user.onboarding_data)
          if (
            this.user.onboarding_data.first_login === 0 &&
            this.user.sso_onboarding_data.first_login === 1
          ) {
            this.openOnboardingPopups(
              this.user,
              [],
              this.onboardContentUser,
              "cyberlab"
            );
            this.onboardingTriggered = true;
          } else if (
            this.user.onboarding_data.first_login === 0 &&
            this.user.sso_onboarding_data.first_login === 0
          ) {
            this.checkRoleBasedOnboardingModal(this.user);
            this.onboardingTriggered = true;
          }
        if (!this.isConstructorExuted) {
          this.getSpecialNotifications();
        }
        this.refreshNotifInterval();
        this.mixPanelService.identifyUser(this.user);
        this.isConstructorExuted = true;
      }
    });

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.scrollbarRef?.scrollTo({ top: 0, left: 0 });
      }
    });

    this.rolesService.currentUserRoles$.subscribe((roles: string[]) => {
      this.userRoles = roles;

      if (this.userRoles.includes("user")) {
        this.showPremiumButton = true;
      }
      // if (
      //   this.userRoles.includes("company-member") ||
      //   this.userRoles.includes("user")
      // ) {
      //   this.showWalletNav = true;
      // }
      if (this.userRoles) {
        this.setSidebar();
      }
    });
    // this.modulesAccessService.moduleAccess$.subscribe(
    //   (moduleAccessData: ModulesAccess | null) => {
    //     this.modulesAccess = moduleAccessData;
    //     if (this.modulesAccess != null) {
    //       this.setSidebar();
    //     }
    //   },
    // );

    // combineLatest([
    //   //"combineLatest" operator combines emissions from multiple Observables into a single Observable.
    //   // this approach ensures that setSidebar() is only called after both currentUserRoles$ and moduleAccess$ Observables have emitted their values
    //   this.rolesService.currentUserRoles$,
    //   this.modulesAccessService.moduleAccess$,
    // ]).subscribe(([roles, moduleAccessData]) => {
    //   if (roles) {
    //     this.userRoles = roles;
    //   }
    //   if (moduleAccessData != null) {
    //     this.modulesAccess = moduleAccessData;
    //   }

    //   if (this.userRoles.length && this.modulesAccess !== null) {
    //     this.setSidebar();
    //   }
    // });

    this.notificationService.notification$.subscribe((number: number) => {
      this.notificationNumber = number;
    });

    this.ssoLogin();
    this.navBar.loading$.subscribe(
      (loading: boolean) => setTimeout(() => (this.isLoading = loading)) // Patches ExpressionChangedAfterItHasBeenCheckedError bug
    ); // Loading bar state
  }

  checkRoleBasedOnboardingModal(user: User): void {
    if (user.role.includes("company-member")) {
      this.openOnboardingPopups(
        user,
        this.accountOnBoardCompanyMemberContant,
        this.onboardContentUser,
        "account"
      );
    } else if (user.role.includes("user")) {
      this.openOnboardingPopups(
        user,
        this.accountOnBoardContentUser,
        this.onboardContentUser,
        "account"
      );
    }
  }

  openOnboardingPopups(
    user: User | null,
    onboardingAccount: OnboardingModalContent[],
    onboardingCyberlab: OnboardingModalContent[],
    typeOnboardingActive: string
  ) {
    const onboardingModalRef: DialogRef = this.dialog.open(
      OnboardingModalsComponent,
      {
        width: "471px",
        data: {
          user,
          onboardingAccount: onboardingAccount,
          onboardingCyberlab: onboardingCyberlab,
          onboardingActive: typeOnboardingActive,
        },
      }
    );

    onboardingModalRef.afterClosed$.subscribe((result: boolean) => {
      this.onboardingService.updateOnboarding().subscribe({
        next: (data: any) => {
          this.updateFirstLoginLocally();
        },
      });
    });
  }
  updateFirstLoginLocally() {
    if (this.user) this.user.onboarding_data.first_login = 1;
    // Get user from localStorage
    const userData = localStorage.getItem("currentUser");
    if (userData) {
      const user: UserData = JSON.parse(userData);
      user.user.onboarding_data.first_login = 1;
      localStorage.setItem("currentUser", JSON.stringify(user));
    }
  }
  ngOnInit(): void {
    /**
     * Reflects the state of lazy-loaded modules downloading
     */
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.navBar.activeLoadings === 0) {
          this.navBar.startLoading();
        }
        this.navBar.activeLoadings++;
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        this.navBar.activeLoadings--;
        if (this.navBar.activeLoadings === 0) {
          this.navBar.stopLoading();
        }
      } else if (event instanceof NavigationError) {
        this.navBar.activeLoadings--;
        if (this.navBar.activeLoadings === 0) {
          this.navBar.stopLoading();
        }
        setTimeout(() => {
          this.router.navigate([event.url], { replaceUrl: true });
        });
        console.log(
          "[App Component] A Navigation Error Occured :",
          event.error
        );
      }
    });
  }

  ngAfterViewInit() {
    // Subscribe to scroll event
    // console.log("scrollbarRef", this.scrollbarRef);
    const isLabInstancePage =
      window.location.pathname.includes("/labs/instance/");
    this.scrollSubscription = this.scrollbarRef!.scrolled.subscribe(
      (e: any) => {
        this.customScrollbarService.setScrollEventData(e);
        // console.log("scroll event", e);
        // If the user has scrolled more than 100px show the scroll button
        if (e.target.scrollTop > 1000 && isLabInstancePage) {
          this.showScrollButton = true;
        } else {
          this.showScrollButton = false;
        }
      }
    );
  }

  scrollToTop() {
    this.scrollbarRef!.scrollTo({ top: 0, left: 0 });
  }

  private ssoLogin(): void {
    /* ------------------ SSO Related ------------------ */
    const authorizationCode = new URLSearchParams(window.location.search).get(
      "code"
    );

    if (authorizationCode) {
      // If the query parameter "code" exists return the queryParams value
      this.authService.isLogInSubj.next(true);
      const state =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
      console.log("[App - Component] %cSSO started", "color:blue");

      this.loginSubscription = this.authService
        .login({
          code: authorizationCode,
          state,
        })
        .subscribe(() => {
          console.log("[App - Component] %cSSO succeeded", "color:green");
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              code: undefined,
            },
            queryParamsHandling: "merge", // remove to replace all query params by provided
          });
        });
      this.loginSubscription.add(() => {
        this.authService.isLogInSubj.next(false);
      });
    } else {
      this.authService.init();
      // this.currentUserSubscription = this.authService.currentUser$.subscribe(
      //   (user) => {
      //     console.log("[App - Component] User: ", user);
      //   },
      // );
    }

    this.queryParamsSubscription = this.route.queryParams // In case the SSO server returned an error log it
      .pipe(filter((params) => params["error"]))
      .subscribe((params) => {
        console.log("[App - Component] %cSSO failed", "color:red");
        console.log('[App - Component] params["error"]', params["error"]);
      });
  }

  toggleAccordion(index: number) {
    if (!(index === this.activeAccordionItemIndex)) {
      this.activeAccordionItemIndex = index;
      this.isOpen = true;
    } else if (index === this.activeAccordionItemIndex && this.isOpen) {
      this.isOpen = false;
      this.activeAccordionItemIndex = 0;
    }
  }

  private setSidebar() {
    let sidbarItems = [
      {
        header: $localize`:@@appComponent.dashboard:Dashboard`,
        img: "assets/icons/dashboard.svg",
        link: "/dashboard",
        hidden: false,
        // shouldHaveAccess: false,
      },
      {
        header: "Labs",
        img: "assets/icons/lab.svg",
        hidden: false,
        items: [
          {
            name: "Labs",
            link: "/labs",
            showRoles: ["user", "company-member"],
          },
          {
            name: $localize`:@@appComponent.activeLabs:Active Labs`,
            link: "/labs/active",
            showRoles: ["user", "company-member"],
          },
          {
            name: $localize`:@@appComponent.labHistory:Lab History`,
            link: "/labs/history",
            showRoles: ["user", "company-member"],
          },
          {
            name: $localize`:@@appComponent.rank:Rank`,
            link: "/ranking",
            showRoles: ["user", "company-member"],
          },
          {
            name: "Labs",
            link: "/company/labs",
            showRoles: ["company-manager"],
          },
          {
            name: $localize`:@@appComponent.labRequests:Lab Requests`,
            link: "/company/labs/requests",
            showRoles: ["company-manager"],
          },
          {
            name: $localize`:@@appComponent.activeInstances:Active Instances`,
            link: "/company/labs/active",
            showRoles: ["company-manager"],
          },
          {
            name: $localize`:@@appComponent.labHistory:Lab History`,
            link: "/company/labs/history",
            showRoles: ["company-manager"],
          },
        ],
        shouldHaveAccess: false,
      },
      {
        header: $localize`:@@appComponent.rank:Rank`,
        img: "assets/icons/trophy-blue.svg",
        showRoles: ["company-manager"],
        hidden: false,
        link: "/company/ranking",
        // shouldHaveAccess: false,
      },
      {
        header: "Certification",
        img: "assets/icons/certificate.svg",
        hidden: !this.modulesAccess.certificationAccess,
        // shouldHaveAccess: "certifications",
        items: [
          {
            name: "Certification",
            link: "/certifications",
            access: "cyber_certification_access",
            showRoles: ["company-manager", "user", "company-member"],
          },
          {
            name: $localize`:@@appComponent.certificationRequests:Certification Requests`,
            link: "/certifications/certification-requests",
            access: "cyber_certification_access",
            showRoles: ["company-manager"],
          },
          {
            name: $localize`:@@appComponent.certificationHistory:Certification History`,
            link: "/certifications/certification-history",
            access: "cyber_certification_access",
            showRoles: ["company-manager", "user", "company-member"],
          },
        ],
      },
      {
        header: $localize`:@@appComponent.hiring:Hiring`,
        img: "assets/icons/briefcase.svg",
        showRoles: ["cyber-hirer"],
        hidden: !this.modulesAccess.hiringAccess,
        items: [
          {
            name: $localize`:@@appComponent.talentHunting:Talent Hunting`,
            link: "/cyber-hiring/candidates",
          },
          {
            name: $localize`:@@appComponent.jobs:Jobs`,
            link: "/cyber-hiring/jobs",
          },
          {
            name: $localize`:@@appComponent.settings:Settings`,
            link: "/cyber-hiring/hiring/settings",
          },
        ],
        shouldHaveAccess: "hiring",
      },
      {
        header: $localize`:@@appComponent.career:Career`,
        img: "assets/icons/briefcase.svg",
        showRoles: ["user", "company-member"],
        hideForRoles: ["cyber-hirer"],
        hidden: !this.modulesAccess.hiringAccess,
        items: [
          {
            name: $localize`:@@appComponent.jobs:Jobs`,
            link: "/cyber-hiring/jobs",
            shouldHaveAccess: "hiring",
          },
          {
            name: $localize`:@@appComponent.profile:Profile`,
            link: "cyber-hiring/profile/overview",
          },
        ],
      },
      {
        header: $localize`:@@appComponent.jobs:Jobs`,
        img: "assets/icons/briefcase.svg",
        showRoles: ["company-manager"],
        link: "/cyber-hiring/jobs",
        hidden: !this.modulesAccess.hiringAccess,
        // shouldHaveAccess: "hiring",
      },
      {
        header: $localize`:@@appComponent.events:Events`,
        img: "assets/icons/flag-captured.svg",
        link: "/events",
        showRoles: ["company-manager", "user", "company-member"],
        hidden: !this.modulesAccess.eventAccess,
        // shouldHaveAccess: "events",
      },
      {
        header: $localize`:@@appComponent.coaching:Coaching`,
        img: "assets/icons/chat-bubble.svg",
        link: "/assistance",
      },
    ];

    if (this.userRoles) {
      sidbarItems = sidbarItems.filter((item) => {
        let shouldHide = true;
        let shouldShow = true;
        // let shouldHaveAccess = true;
        if (item.hideForRoles) {
          shouldHide = !item.hideForRoles.some((role: string) =>
            this.userRoles.includes(role)
          );
        }

        if (item.showRoles) {
          shouldShow = item.showRoles.some((role: string) =>
            this.userRoles.includes(role)
          );
        }
        // if (item.shouldHaveAccess) {
        //   shouldHaveAccess = this.checkModuleAccess(
        //     item.shouldHaveAccess,
        //     this.modulesAccess,
        //   );
        // }

        return shouldHide && shouldShow && !item.hidden;
        // return shouldHide && shouldShow && shouldHaveAccess;
      });
      console.log("sidbarItems", sidbarItems);
      sidbarItems = sidbarItems.map((item) => {
        if (item.items) {
          item.items = item.items.filter((subItem: any) => {
            let shouldHide = true;
            let shouldShow = true;
            if (subItem.hideForRoles) {
              shouldHide = !subItem.hideForRoles.some((role: string) =>
                this.userRoles.includes(role)
              );
            }

            if (subItem.showRoles) {
              shouldShow = subItem.showRoles.some((role: string) =>
                this.userRoles.includes(role)
              );
            }
            return shouldHide && shouldShow;
          });
        }
        return item;
      });
      this.sidebarLinks = [...sidbarItems];
    }
  }

  private getSpecialNotifications() {
    this.isSecialNotificationsLoaded = false;
    this.notificationService
      .getSpecialNotifications(false, 1)
      .subscribe((resp: any) => {
        this.notifications = resp.data.data;
        this.notificationsLastPage = resp.data.last_page;
        this.notificationNumber = resp.data.total;
        this.notifications = this.notifications.map(
          (notification: GeneralNotif) => {
            notification.data = JSON.parse(notification.data);
            if (notification.path) {
              notification.path = this.translateNotificationPath(notification);
            }
            notification.content = this.stringTemplateParser(
              notification.template,
              notification.data
            );
            return notification;
          }
        );
        this.isSecialNotificationsLoaded = true;
      });
  }

  isApproveReject(type: string) {
    return ["assistanceRequestManager", "LabRequest"].includes(type);
  }

  refreshNotifInterval() {
    this.specialNotifInterval = setInterval(() => {
      if (!this.user) {
        clearInterval(this.specialNotifInterval);
        return;
      }
      this.getSpecialNotifications();
    }, 900000);
  }
  stringTemplateParser(expression: any, valueObj: any) {
    const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;
    const text = expression.replace(
      templateMatcher,
      (substring: any, value: any, index: any) => {
        value = valueObj[value];
        return value;
      }
    );
    return text;
  }

  showNotificationPopup() {
    this.updateLastNotificationCheck();

    this.dialog.open(NotificationPopupComponent, {
      data: {
        notifications: this.notifications,
        lastPage: this.notificationsLastPage,
        notificationNumber: this.notificationNumber,
      },
      width: "440px",
      windowClass: "notificationPopup",
    });
  }

  updateLastNotificationCheck() {
    this.notificationService.updateLastNotificationCheck().subscribe(() => {
      this.notificationNumber = 0;
    });
  }

  showNavigationMenu() {
    const dialogRef = this.dialog.open(NavigationMenuComponent, {
      width: "245px",
      windowClass: "navigationMenuPopup",
    });
  }

  toggleMobileSideBar() {
    this.isMobileSidebarMode = !this.isMobileSidebarMode;
  }

  mobileLinkClicked(link: string) {
    // this.router.navigate([link]);
    this.toggleMobileSideBar();
  }

  translateNotificationPath(notification: any) {
    if (notification.path == "event_page") {
      // if (notification.data.request_id) {
      //   notification.queries = [
      //     {
      //       key: "request",
      //       value: notification.data.request_id,
      //     },
      //   ];
      // }

      return `/events/${notification.data.event_id}`;
    }
    if (notification.path == "assistance_session_page") {
      return `/assistance/sessions/${notification.data.resource_id}`;
    }

    if (notification.path == "active_labs_page") {
      return `/labs/active`;
    }

    if (notification.path == "lab_scenario_path") {
      return `/labs/scenario/${notification.data.resource_type}/${notification.data.resource_id}`;
    }

    if (notification.path == "job_details_page") {
      return `/cyber-hiring/jobs//${notification.data.resource_id}`;
    }

    if (
      notification.path == "hirer_hiring_request_page" ||
      notification.path == "user_hiring_request_page"
    ) {
      return `/cyber-hiring/hiring-request/${notification.data.resource_id}`;
    }

    if (
      notification.path == "hirer_job_application_page" ||
      notification.path == "user_job_application_page"
    ) {
      return `/cyber-hiring/job-application/${notification.data.resource_id}`;
    }

    if (notification.path == "profile_wallet_page") {
      return environment.frontend.account + "/user/wallet";
    }

    if (
      notification.path == "certification_details_page" ||
      notification.path == "certification_instance_page"
    ) {
      return `/certifications/${notification.data.resource_id}`;
    }

    if (notification.path == "certification_request_page") {
      return `/certifications/certification-requests`;
    }

    if (notification.path == "lab_requests_page") {
      return `/company/labs/requests`;
    }

    if (notification.path == "lab_instance_page") {
      return `/labs/instance/${notification.data.resource_id}`;
    }

    return notification.path;
  }

  showSubscriptionMenu() {
    const dialogRef = this.dialog.open(SubscriptionMenuComponent, {
      width: "300px",
      windowClass: "navigationMenuPopup",
    });

    dialogRef.afterClosed$.subscribe((result) => {
      if (result) {
        // window.location.href = environment.frontend.account + "/subscribe";
        // this.router.navigate(["/subscribe"]);
      }
    });
  }

  // private checkModuleAccess(
  //   moduleToAccess: string | boolean | undefined,
  //   userAccessModules: ModulesAccess,
  // ) {
  //   switch (moduleToAccess) {
  //     case "certifications":
  //       return userAccessModules.cyber_certification_access === 1;
  //     case "events":
  //       return userAccessModules.cyberevent_access === 1;

  //     case "hiring":
  //       return userAccessModules.cyber_hiring_access === 1;

  //     case "cyberlearn":
  //       return userAccessModules.cyberlearn_access === 1;

  //     case "cyberlab":
  //       return userAccessModules.cyberlab_access === 1;

  //     case "cyberawarness":
  //       return userAccessModules.cyber_awareness_access === 1;

  //     default:
  //       return false;
  //   }
  // }
  // async initlizeMixPanel() {
  //   await this.mixPanelService.init();
  // }
}
