import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { AssistanceRequestData, NewSessionData } from "@interfaces/assistance";
import { PaginationResp } from "@interfaces/generic-pagination-resp";
import { GenericResp } from "@interfaces/generic-resp";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AssistanceService {
  private bakendApi = environment.backend.cyberlab;
  constructor(private http: HttpClient) {}

  getCreateFormEnums(): Observable<GenericResp<any>> {
    return this.http.get<GenericResp<any>>(
      `${this.bakendApi}/api/assistance/enum`
    );
  }

  createRequest(payload: NewSessionData): Observable<GenericResp<any>> {
    // ["assistance_motive_id", "duration", "description", "resource_id","purchase_method"]

    return this.http.post<GenericResp<any>>(
      `${this.bakendApi}/api/assistance/create`,
      payload
    );
  }

  getRequestsIndex(
    page = 1,
    data: any = null
  ): Observable<PaginationResp<any>> {
    return this.http
      .post<GenericResp<PaginationResp<any>>>(
        `${this.bakendApi}/api/assistance/paginated?page=${page}`,
        data
      )
      .pipe(map((resp: GenericResp<PaginationResp<any>>) => resp.data));
  }

  getSpecificRequest(id: number): Observable<AssistanceRequestData> {
    const input = {
      timezone_offset: new Date().getTimezoneOffset(),
    };
    return this.http
      .post<GenericResp<AssistanceRequestData>>(
        `${this.bakendApi}/api/assistance/request/${id}`,
        input
      )
      .pipe(map((resp: GenericResp<AssistanceRequestData>) => resp.data));
  }

  manageAssistanceRequestCManager(id: number, decision: any) {
    const data = {
      decision: decision,
    };

    return this.http.post(
      `${this.bakendApi}/api/assistance/request/${id}/manage`,
      data
    );
  }

  setAdminAvailabilityRanges(id: number, ranges: any) {
    const data = {
      ranges: JSON.stringify(ranges),
      timezone_offset: new Date().getTimezoneOffset(),
    };

    return this.http.post(
      `${this.bakendApi}/api/assistance/request/${id}/adminRanges`,
      data
    );
  }

  setAssistanceStartTime(id: number, start_time: string) {
    const data = {
      start_time: start_time,
      timezone_offset: new Date().getTimezoneOffset(),
    };

    return this.http.post(
      `${this.bakendApi}/api/assistance/request/${id}/userStart`,
      data
    );
  }

  sendAssistanceReview(session_id: number, rating: number, review: any) {
    const data = {
      rating: rating,
      user_comments: review,
    };

    return this.http.post(
      `${this.bakendApi}/api/assistance/request/${session_id}/review`,
      data
    );
  }
}
