import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FeatureFlagsService {
  public backendApi = environment.backend.cyberlab;

  private featuresSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  // private featuresLoadedSubject: BehaviorSubject<boolean> =
  //   new BehaviorSubject<boolean>(false);

  public features: Observable<any> = this.featuresSubject.asObservable();
  // public featuresLoaded: Observable<boolean> =
  //   this.featuresLoadedSubject.asObservable();

  constructor(public http: HttpClient) {}

  public loadFeatureFlags() {
    this.http
      .get(`${this.backendApi}/api/feature-flags`, {
        headers: { ignoreError: "true" },
      })
      .subscribe({
        next: (data: any) => {
          this.featuresSubject.next(data.data);
          // this.featuresLoadedSubject.next(true);
        },
        error: (error) => {
          console.error("Failed to fetch feature flags", error);
        },
      });
  }

  getFeatureFlags(): Observable<any> {
    return this.features; // This allows components to subscribe to the feature flags
  }
  // isFeaturesFlagsLoaded(): Observable<boolean> {
  //   return this.featuresLoaded; // This allows components to subscribe to the feature flags
  // }
}
