import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";

import { AngularSvgIconModule, SvgLoader } from "angular-svg-icon";
import { NgScrollbarModule } from "ngx-scrollbar";
import { LottieModule } from "ngx-lottie";
// import { CalendarModule } from "angular-calendar";
// import { DateAdapter } from "angular-calendar";
// import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import player from "lottie-web";
import * as Sentry from "@sentry/angular";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ClarityModule } from "./analytics/clarity.module";
import { environment } from "@environments/environment";
import { JwtInterceptor } from "@interceptors/jwt.interceptor";
import { HttpErrorInterceptor } from "@interceptors/http-error.interceptor";
import { WalletNavComponent } from "@components/wallet-nav/wallet-nav.component";
import { ShowForRolesDirective } from "@directives/show-for-roles.directive";
import { SvgHttpLoader as CustomSvgLoader } from "@services/svg-loader.service";
import { LoadingInterceptor } from "@interceptors/loading.interceptor";
import { ToastComponent } from "@components/toast/toast.component";
import { BackendErrorInterceptor } from "@interceptors/backend-error.interceptor";
import { AccessErrorInterceptor } from "@interceptors/access-error.interceptor";
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from "ngx-google-analytics";
import { DropdownProfileMenuComponent } from "./components/dropdown-profile-menu/dropdown-profile-menu.component";
import { FeatureFlagDirective } from "@directives/feature-flag.directive";
import { AppInitializeService } from "@services/app-initializer.service";
import { NavbarLogosSectionComponent } from "@components/navbar-logos-section/navbar-logos-section.component";

export function playerFactory() {
  return player;
}

// export function initializeApp(appInitService: AppInitializeService) {
//   return () => appInitService.load();
// }

export function initializeApp(appInitService: AppInitializeService) {
  return async () => {
    // Wait for the app initialization to complete
    await appInitService.load();

    // Initialize Sentry after app initialization
    Sentry.init({
      environment: environment?.monitoring?.environment,
      dsn: environment?.monitoring?.sentryDSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: environment?.monitoring?.tracePropagationTargets,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  };
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    WalletNavComponent,
    ShowForRolesDirective,
    NavbarLogosSectionComponent,
    ToastComponent,
    AngularSvgIconModule.forRoot({
      loader: {
        provide: SvgLoader,
        useClass: CustomSvgLoader,
      },
    }),
    NgScrollbarModule,
    LottieModule.forRoot({ player: playerFactory }),
    // CalendarModule.forRoot({
    //   provide: DateAdapter,
    //   useFactory: adapterFactory,
    // }),
    ClarityModule.forRoot({
      enabled: !!environment.analytics?.clarityProjectId,
      projectId: environment.analytics?.clarityProjectId,
    }),
    NgxGoogleAnalyticsModule.forRoot(
      environment.analytics?.googleAnalyticsTrackingCode
    ),
    NgxGoogleAnalyticsRouterModule,
    DropdownProfileMenuComponent,
    FeatureFlagDirective,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitializeService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackendErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {}, // eslint-disable-line
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
