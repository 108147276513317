import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { GenericResp } from "@interfaces/generic-resp";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  private readonly backendApi = environment.backend.cyberlab;

  constructor(public http: HttpClient) {}

  getLanguages(): any {
    return this.http.get(`${this.backendApi}/api/languages`);
  }

  getPreferredLanguage() {
    return this.http.get(
      `${this.backendApi}/api/languages/user/PreferredLanguage`
    );
  }

  getUserLanguages(): any {
    return this.http.get(`${this.backendApi}/api/v2/languages/user`);
  }

  setUserPreferredLanguage(languageId: number): Observable<any> {
    return this.http.post<any>(
      `${this.backendApi}/api/languages/user/setPreferredLanguage`,
      { language_id: languageId }
    );
  }
}
