import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import {
  CustomResponse,
  PaginationResp,
} from "@interfaces/generic-pagination-resp";
import { GenericResp } from "@interfaces/generic-resp";
import {
  BulkRequests,
  LabRequest,
  LabRequestStats,
  RejectBulkRequests,
} from "@interfaces/lab-request";
import { PaginationParms } from "@interfaces/pagination";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RequestService {
  private readonly backendApi = environment.backend.cyberlab;

  constructor(public http: HttpClient) {}

  getCompanyMembersLabRequests(
    page = 1,
    data: PaginationParms
  ): Observable<PaginationResp<LabRequest[]>> {
    return this.http
      .post<GenericResp<PaginationResp<LabRequest[]>>>(
        `${this.backendApi}/api/VoucherRequestPaginated?page=${page}`,
        data
      )
      .pipe(
        map((resp: GenericResp<PaginationResp<LabRequest[]>>) => resp.data)
      );
  }

  aprouveRequestLab(
    requestId: number,
    payload: any
  ): Observable<GenericResp<any>> {
    console.log("payload", payload);
    return this.http.post<GenericResp<any>>(
      `${this.backendApi}/api/voucher-request/${requestId}/approve`,
      payload
    );
  }

  rejectRequestLab(requestId: number) {
    return this.http.post(
      `${this.backendApi}/api/voucher-request/${requestId}/reject`,
      {}
    );
  }

  getCompanyMembersLabRequestsStats(): Observable<LabRequestStats> {
    return this.http
      .get<GenericResp<LabRequestStats>>(
        `${this.backendApi}/api/company/statistics/labs/requests`
      )
      .pipe(map((resp: GenericResp<LabRequestStats>) => resp.data));
  }

  getLabRequests(
    page: number,
    filters: any,
    statusLab = "all"
  ): Observable<CustomResponse<PaginationResp<LabRequest[]>>> {
    let url = `${this.backendApi}/api/v2/Company/lab-requests?page=${page}`;
    console.log("filters", filters);

    if (filters.perPage) {
      url += `&perPage=${filters.perPage}`;
    }

    if (statusLab) {
      url += `&status=${statusLab}`;
    }

    if (filters.filterByValue) {
      url += `&filterByValue=${filters.filterByValue}`;
    }
    if (filters.sortByType) {
      url += `&sortByType=${filters.sortByType}`;
    }
    if (filters.sortByValue) {
      url += `&sortByValue=${filters.sortByValue}`;
    }

    return this.http
      .get<GenericResp<CustomResponse<PaginationResp<LabRequest[]>>>>(url)
      .pipe(
        map(
          (resp: GenericResp<CustomResponse<PaginationResp<LabRequest[]>>>) =>
            resp.data
        )
      );
  }

  requestLab(instanceId: any, data: any): Observable<GenericResp<any>> {
    return this.http.post<GenericResp<any>>(
      `${this.backendApi}/api/lab/${instanceId}/request`,
      data
    );
  }

  approveAllRequests(data: BulkRequests): Observable<GenericResp<any>> {
    return this.http.post<GenericResp<any>>(
      `${this.backendApi}/api/v2/voucher-requests/approve`,
      data
    );
  }

  rejectAllRequests(data: RejectBulkRequests): Observable<GenericResp<any>> {
    return this.http.post<GenericResp<any>>(
      `${this.backendApi}/api/v2/voucher-requests/reject`,
      data
    );
  }
}
