import { Injectable } from "@angular/core";
import { BehaviorSubject, skip, skipWhile, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RolesService {
  public currentUserRoles$: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >([]);

  currentRoles: string[] = [];
  constructor() {
    this.currentUserRoles$.subscribe((roles: string[]) => {
      this.currentRoles = roles;
    });
  }

  isAllowed(allowedRoles: Array<string>): Promise<boolean> {
    return new Promise((resolve) => {
      this.currentUserRoles$
        .pipe(
          skipWhile((roles) => {
            return roles.length === 0;
          })
        )
        .subscribe((roles) => {
          if (roles) {
            resolve(this.arrayMatch(allowedRoles, roles));
          }
        });
    });
  }

  rolesPromise(): Promise<string[]> {
    return new Promise((resolve) => {
      this.currentUserRoles$
        .pipe(
          skipWhile((roles) => {
            return roles.length === 0;
          })
        )
        .subscribe((roles) => {
          if (roles) {
            resolve(roles);
          }
        });
    });
  }

  private arrayMatch(array1: Array<string>, array2: Array<string>): boolean {
    // Returns true if two arrays have matching values
    if (!!array1 && !!array2) {
      if (!isNaN(array1.length) && !isNaN(array2.length)) {
        const intersection = array1.filter(
          (element: string) => array2.indexOf(element) !== -1
        );
        return !!intersection.length;
      }
    }
    return false;
  }
}
