import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Colors } from "@interfaces/charts";
import { PaginationInfo, PaginationParms } from "@interfaces/pagination";
import { ToastService } from "./toast.service";
import { Location } from "@angular/common";

interface QueryParams {
  perPage?: number | null;
  currentPage?: number | null;
  searchText?: string | null;
  sortBy?: string | null;
  sortDirection?: string | null;
  themeId?: number | null;
  themeName?: string | null;
  status: string | null;
  rankType?: string | null;
}

@Injectable({
  providedIn: "root",
})
export class UtilitiesService {
  modulesAccess = {
    cyberlearnAccess: true,
    cyberlabAccess: true,
    eventAccess: true,
    cyberawarenessAccess: false,
    certificationAccess: true,
    hiringAccess: true,
    classroomAccess: false,
  };
  constructor(
    private readonly toastService: ToastService,
    private readonly router: Router,
    private readonly location: Location
  ) {}
  filterPaginationParams(paginationParams: PaginationParms) {
    const newPaginationParams: PaginationParms = {};
    for (const [key, value] of Object.entries(paginationParams)) {
      if (value !== "" && value !== undefined && value !== null) {
        newPaginationParams[key] = value;
      }
    }
    return newPaginationParams;
  }

  calculateTime(seconds: number) {
    const time = seconds--;
    let hours: string | number = Math.floor(time / 3600);
    let minutes: string | number = Math.floor((time - hours * 3600) / 60);
    let extraSeconds: string | number = time - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (extraSeconds < 10) {
      extraSeconds = "0" + extraSeconds;
    }

    return `${hours}:${minutes}:${extraSeconds}`;
  }

  async copyToClipboard(textToCopy: string) {
    try {
      const tmpInput = document.createElement("input");
      tmpInput.setAttribute("value", textToCopy);
      document.body.appendChild(tmpInput);
      tmpInput.select();
      await navigator.clipboard.writeText(textToCopy);
      document.body.removeChild(tmpInput);
      this.toastService.initiate({
        toastClass: "success-toast",
        toastTitle: $localize`:@@copiedToClipboard:Copied to clipboard`,
        iconPath: "/assets/icons/copy.svg",
        show: true,
      });
    } catch (err) {
      console.error("Could not copy text: ", err);
    }
  }

  // a function that takes a key color and returns equivalent of it as color hex
  getSpecificChartColor(key: keyof Colors) {
    const colors: Colors = {
      primary: "#6BABBB",
      secondary: "#3B82F6",
      tertiary: "#D34345",
      fourth: "#A855F7",
    };

    return colors[key];
  }
  // a function that takes a number of colors wanted and returns equivalent of it as colors

  getChartColors(countColors: number, isEmpty = false) {
    if (!isEmpty) {
      const colors = ["#6BABBB", "#3B82F6", "#D34345", "#A855F7"];
      return colors.slice(0, countColors);
    }
    return [...Array(countColors)].fill("rgba(145, 158, 171,0.2)");
  }

  updateRouteParameters(route: string, filters: any) {
    const removedQueryParams: QueryParams = {} as QueryParams;
    const updatedParams: QueryParams = {} as QueryParams;
    const params: QueryParams = {
      perPage: filters.perPage,
      currentPage: filters.currentPage,
      searchText: filters.filterByValue,
      sortBy: filters.sortByValue,
      sortDirection: filters.sortByType,
      themeId: filters.themeId,
      themeName: filters.themeName,
      status: filters.status,
      rankType: filters.rankType,
    };
    if (params.perPage) {
      removedQueryParams.perPage = null;
      updatedParams.perPage = params.perPage;
    }
    if (params.currentPage) {
      removedQueryParams.currentPage = null;
      updatedParams.currentPage = params.currentPage;
    }
    if (params.searchText) {
      removedQueryParams.searchText = null;
      updatedParams.searchText = params.searchText;
    }
    if (params.sortBy) {
      removedQueryParams.sortBy = null;
      updatedParams.sortBy = params.sortBy;
    }
    if (params.sortDirection) {
      removedQueryParams.sortDirection = null;
      updatedParams.sortDirection = params.sortDirection;
    }
    if (params.themeId) {
      removedQueryParams.themeId = null;
      updatedParams.themeId = params.themeId;
    }
    if (params.themeName) {
      removedQueryParams.themeName = null;
      updatedParams.themeName = params.themeName;
    }
    if (params.status) {
      removedQueryParams.status = null;
      updatedParams.status = params.status;
    }
    if (params.rankType) {
      removedQueryParams.rankType = null;
      updatedParams.rankType = params.rankType;
    }

    this.router.navigate([], {
      queryParams: removedQueryParams,
      queryParamsHandling: "merge",
    });
    const urlTree = this.router.createUrlTree([route], {
      queryParams: updatedParams,
      queryParamsHandling: "merge",
    });

    //Update route with Query Params
    this.location.go(urlTree.toString());
  }

  convertUTCToLocalTime(utcDate: string): any {
    if (utcDate.includes("Z")) {
      return new Date(utcDate);
    }
    // All dates from backend comes in utc 0
    const date = new Date(utcDate + " UTC");
    return date.toISOString();
  }

  formatDateForBackend(date: any) {
    const formattedDate = new Date(date);
    const formattedDateStr =
      formattedDate.getDate() +
      "-" +
      (formattedDate.getMonth() + 1 < 9
        ? "0" + (formattedDate.getMonth() + 1)
        : formattedDate.getMonth() + 1) +
      "-" +
      formattedDate.getFullYear() +
      " " +
      (formattedDate.getHours() < 10
        ? "0" + formattedDate.getHours()
        : formattedDate.getHours()) +
      ":" +
      (formattedDate.getMinutes() < 10
        ? "0" + formattedDate.getMinutes()
        : formattedDate.getMinutes()) +
      ":00";

    return formattedDateStr;
  }

  dateToMonthYear(date: string) {
    // Return MM/YYYY
    const formattedDate = new Date(date);
    const formattedDateStr =
      (formattedDate.getMonth() + 1 < 9
        ? "0" + (formattedDate.getMonth() + 1)
        : formattedDate.getMonth() + 1) +
      "/" +
      formattedDate.getFullYear();
    return formattedDateStr;
  }

  getMaxPanelEventName(key: string): string {
    const mixPanelEvents: any = {
      buy_lab: "Buy Lab",
      request_lab: "Request Lab",
      start_Lab: "Start Lab",
      revert_lab: "Revert Lab",
      stop_lab: "Stop Lab",
      submit_flag: "Submit Flag",
      certification_purchased: "Certification purchased",
      certification_started: "Certification started",
      certification_terminated: "Terminated Certification",
      send_request: "Send hiring request",
      request_reschedule: "Request To reschedule meeting",
      approve_request_date: "Approve hiring meeting date",
      cancel_request: "Canel Hiring request",
      reject_request: "Reject hiring request",
      send_request_verdict: "Send verdict hiring request",
      reschedule_request: "Reschedule Hiring request",
      job_created: "JobOffer Created",
      apply_job: "Apply for job",
      request_application_reschedule:
        "Request To reschedule job application meeting",
      approve_application_date: "Approve job application meeting date",
      cancel_application_request: "Canel job application",
      reject_application_request: "Reject job application",
      send_application_verdict: "Send verdict on job appliction",
      reschedule_application_request: "Reschedule job application meeting date",
    };
    return mixPanelEvents[key];
  }

  getModulesAccess(user: any) {
    if (!user.company) {
      console.log("user inside get modules access");
      this.modulesAccess.cyberawarenessAccess = false;
      if (user.role?.includes("cyber-hirer")) {
        this.modulesAccess.hiringAccess = true;
      }
      return this.modulesAccess;
    }

    const settings = user.company.settings;
    let classroomAccess = false;
    const subscription = user.subscription;
    if (subscription) {
      const features = JSON.parse(subscription.features);
      if (features.calssroom_active) {
        classroomAccess = true;
      }
    }

    this.modulesAccess = {
      cyberlearnAccess: settings.cyberlearn_access,
      cyberlabAccess: settings.cyberlab_access,
      eventAccess: settings.cyberevent_access,
      hiringAccess:
        (user.role?.includes("cyber-hirer") || user.role?.includes("user")) &&
        settings.cyber_hiring_access
          ? true
          : false,
      cyberawarenessAccess: settings.cyber_awareness_access,
      certificationAccess: settings.cyber_certification_access,
      classroomAccess: classroomAccess,
    };

    return this.modulesAccess;
  }

  updatePaginationBar(data: {
    page: number;
    pageCutLow: number;
    pageCutHigh: number;
    paginationPages: number[];
    paginationInfo: PaginationInfo;
  }) {
    const paginationPages: number[] = [];
    // Determine how many pages to show after the current page index
    data.pageCutLow = data.page - 1;
    data.pageCutHigh = data.page + 1;
    if (data.page === 1) {
      data.pageCutHigh += 2;
    } else if (data.page === 2) {
      data.pageCutHigh += 1;
    }
    // Determine how many pages to show before the current page index
    if (data.page === data.paginationInfo.lastPage) {
      data.pageCutLow -= 2;
    } else if (data.page === data.paginationInfo.lastPage - 1) {
      data.pageCutLow -= 1;
    }

    for (let p = data.pageCutLow; p <= data.pageCutHigh; p++) {
      if (p === 0) {
        p += 1;
      }
      if (p > data.paginationInfo.lastPage) {
        continue;
      }
      paginationPages.push(p);
    }
    data.paginationPages = paginationPages;
    return data;
  }
}
