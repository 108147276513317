<div
  class="border rounded shadow-card modal-content bg-black-secondary border-black-primary"
>
  <!-- Step 1 -->
  <div class="modal-content flex max-h-[300px] flex-col">
    <div class="p-3 modal-header relative">
      <div class="flex items-center justify-between">
        <div class="flex w-full relative flex-col items-start">
          <h3
            class="text-sm font-medium leading-4 font-chakra text-white-primary mb-3"
          >
            Notifications
          </h3>
          <div class="flex w-full items-center justify-between">
            <div class="flex items-center">
              <button
                (click)="getUnReadNotifications()"
                [ngClass]="{
                  'bg-green-secondary text-white-primary':
                    activeTab === 'unread',
                  'border border-black-secondary text-gray-primary':
                    activeTab !== 'unread'
                }"
                class="mr-3 bg-green-secondary rounded-[31px] py-1 px-4 text-center text-xs leading-none text-white-primary capitalize font-chakra block"
              >
                Unread
              </button>
              <button
                (click)="getReadNotifications()"
                [ngClass]="{
                  'bg-green-secondary text-white-primary': activeTab === 'read',
                  'border border-black-secondary text-gray-primary':
                    activeTab !== 'read'
                }"
                class="rounded-[31px] py-1 px-4 text-center text-xs leading-none capitalize font-chakra block"
              >
                Read
              </button>
            </div>

            <div *ngIf="activeTab === 'unread'">
              <button
                [disabled]="notifications.length === 0 || isLoading"
                appRipple
                (click)="readBulkNotification()"
                class="rounded-sm bg-green-secondary text-white-primary py-1 px-4 text-center text-xs leading-none capitalize font-chakra block"
              >
                Read All
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="isLoading"
        class="absolute z-10 bottom-[-2px] left-[0px] w-[435px]"
      >
        <div class="progress w-[435px]">
          <div class="indeterminate"></div>
        </div>
      </div>
    </div>

    <hr class="border-t border-black-primary" />

    <div class="modal-body scrollbar-container" #scrollContainer>
      <ng-container *ngIf="notifications.length; else noNotifications">
        <div
          *ngFor="let notif of notifications"
          (mouseover)="mouseEnter(notif.id)"
          (click)="onLinkClick($event, notif)"
          (mouseleave)="mouseLeave()"
          class="relative block cursor-pointer"
          [ngClass]="{
            'cursor-pointer hover:bg-[#1E232A]': notif.path !== null
          }"
        >
          <div class="w-full px-4 py-5">
            <!-- <svg-icon
              src="/assets/icons/read-indicator.svg"
              class="absolute left-2 top-2"
            ></svg-icon> -->
            <div class="flex items-start">
              <svg-icon
                [svgStyle]="{ 'height.px': 24, 'width.px': 24 }"
                [src]="notif.iconPath"
                alt=""
                class="h-8 green-icon mr-3 w-9 mt-3"
              ></svg-icon>
              <div class="flex flex-col">
                <div class="mb-4">
                  <span class="text-sm leading-5 text-white-primary">{{
                    notif.content
                  }}</span>
                  <div
                    (click)="readNotification(notif); $event.stopPropagation()"
                    *ngIf="!notif.read && hoveredNotif === notif.id"
                    class="absolute z-10 brighter cursor-pointer h-[25px] w-[25px] bg-[#2a313b] flex items-center justify-center rounded-full right-[5px] top-[40%]"
                  >
                    <svg-icon
                      [svgStyle]="{
                        'width.px': 15,
                        'height.px': 15
                      }"
                      src="assets/icons/check.svg"
                    >
                    </svg-icon>
                  </div>
                </div>
                <span class="text-xs font-medium leading-5 text-gray-primary">{{
                  notif.created_at | date : "short"
                }}</span>
              </div>
            </div>
          </div>
          <hr class="border-t border-black-primary" />
        </div>
      </ng-container>
      <ng-template #noNotifications>
        <div
          class="flex-col shadow-card border bg-black-secondary rounded border-black-primary relative pb-8"
        >
          <div class="items-center justify-center w-full h-full">
            <div class="flex flex-col items-center justify-center">
              <svg-icon
                src="/assets/icons/empty-table-2.svg"
                [svgStyle]="{ 'height.px': 150, 'width.px': 200 }"
              ></svg-icon>

              <div class="text-center">
                <h3 class="leading-8 text-white-primary font-chakra">
                  You're all caught up
                </h3>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
