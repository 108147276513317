import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { NavBarService } from "@services/nav-bar.service";

@Injectable({
  providedIn: "root",
})
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private readonly navBar: NavBarService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.get("ignoreLoadingInterceptor")) {
      return next.handle(request);
    }
    if (this.navBar.activeLoadings === 0) {
      console.log("[Loading Service] %cStart loading", "color: green");
      this.navBar.startLoading();
    }

    this.navBar.activeLoadings++;

    return next.handle(request).pipe(
      finalize(() => {
        this.navBar.activeLoadings--;
        if (this.navBar.activeLoadings === 0) {
          console.log("[Loading Service] %cStop loading", "color: red");
          this.navBar.stopLoading();
        }
      })
    );
  }
}
