import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CustomNgScrollbarService {
  // constructor() {}

  readonly scrolledSubject = new BehaviorSubject<any>({});
  readonly scrolledSubject$: Observable<any> =
    this.scrolledSubject.asObservable();

  setScrollEventData(eventData: any) {
    this.scrolledSubject.next(eventData);
  }
  // initiate(data: ToastData) {
  //   this.data = { ...data, show: true };
  //   this.open.next(data);
  // }
}
