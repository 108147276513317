import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";

import { AuthService } from "./auth.service";
import { Observable } from "rxjs";
import { UtilitiesService } from "@services/utilities.service";

@Injectable({
  providedIn: "root",
})
export class ModuleAcessGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private utilitiesService: UtilitiesService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!route.data["module"]) return true;
    const userData = this.authService.currentUserData;
    if (userData) {
      const user = userData.user;
      const moduleAccess: any = this.utilitiesService.getModulesAccess(user);
      if (!moduleAccess[route.data["module"]]) {
        this.router.navigate(["/404"]);
      }
      return true;
    }
    return false;
  }
}
