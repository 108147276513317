import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { Events } from "@interfaces/events";
import { PaginationResp } from "@interfaces/generic-pagination-resp";
import { GenericResp } from "@interfaces/generic-resp";
import { map, Observable } from "rxjs";
import { saveAs as importedSaveAs } from "file-saver";

@Injectable({
  providedIn: "root",
})
export class CtfEventsService {
  private readonly backendApi = environment.backend.cyberlab;
  constructor(public http: HttpClient) {}

  createCTF(data: any) {
    return this.http.post(`${this.backendApi}/api/ctf-event`, data);
  }
  getEvents(page = 1, data: any): Observable<PaginationResp<Events[]>> {
    return this.http
      .post<GenericResp<PaginationResp<any[]>>>(
        `${this.backendApi}/api/ctf-event/upcoming?page=${page}`,
        data
      )
      .pipe(map((resp: GenericResp<PaginationResp<Events[]>>) => resp.data));
  }

  getCtfByID(id: number) {
    return this.http.get(
      `${this.backendApi}/api/ctf-event/${id}`,
      { headers: { ignoreLoadingInterceptor: "true" } } // Skip HTTP Loading Interceptor
    );
  }

  getCtfEditByID(id: number) {
    return this.http.get(`${this.backendApi}/api/ctf-event/edit/${id}`);
  }

  enrollInCtf(id: number, selectedMembers: any[] = []) {
    return this.http.post(
      `${this.backendApi}/api/event-enrollment-requests/request`,
      {
        event_id: id,
        members: selectedMembers,
      }
    );
  }

  enrollInEventDirectly(id: number, selectedMembers: any[] = []) {
    return this.http.post(`${this.backendApi}/api/ctf-event/${id}`, {
      members: selectedMembers,
    });
  }

  cancelEnrollmentRequest(id: number) {
    return this.http.post(
      `${this.backendApi}/api/event-enrollment-requests/cancel/${id}`,
      {}
    );
  }

  updateCtfById(id: number, params: any) {
    return this.http.post(
      `${this.backendApi}/api/ctf-event/edit/${id}`,
      params,
      {
        headers: { ignoreLoadingInterceptor: "true" },
      }
    );
  }

  getCountries() {
    return this.http.get(`${this.backendApi}/api/ctf-event/countries`);
  }
  getRequests(params: any, ignoreLoding = false) {
    let url = `${this.backendApi}/api/event-enrollment-requests/index`;
    url += `?page=${params.page}&event_id=${params.event_id}`;
    if (params.status) {
      url += `&status=${params.status}`;
    }

    if (params.filterByValue) {
      url += `&filterByValue=${params.filterByValue}`;
    }

    if (params.perPage) {
      url += `&perPage=${params.perPage}`;
    }

    if (params.request_id) {
      url += `&request_id=${params.request_id}`;
    }

    if (ignoreLoding) {
      return this.http.get(url, {
        headers: { ignoreLoadingInterceptor: "true" },
      });
    }

    return this.http.get(url);
  }

  manageRequest(data: {
    event_id: number;
    requests: any[];
    display_names: any[];
    status: string;
  }) {
    return this.http.post(
      `${this.backendApi}/api/event-enrollment-requests/manage`,
      data
    );
  }

  quitEvent(eventId: number) {
    return this.http.post(
      `${this.backendApi}/api/ctf-event/quit/${eventId}`,
      {}
    );
  }

  exportLeaderboard(id: number) {
    return this.http
      .get(`${this.backendApi}/api/ctf-event/export-leaderboard/${id}`, {
        observe: "response",
        responseType: "blob",
      })
      .subscribe(
        (response: any) => {
          console.log("response", response);
          const blob = new Blob([response.body], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          // take the name from the response header
          const contentDisposition = response.headers.get(
            "Content-Disposition"
          );
          const fileName = contentDisposition
            .split(";")[1]
            .split("=")[1]
            .trim();

          importedSaveAs(blob, fileName);
        },
        (e) => {
          console.log(e);
        }
      );
  }

  donloadInvitationTemplate(type: string, playMode = "dedicated") {
    return this.http
      .get(
        `${this.backendApi}/api/ctf-event-invitation-template/download?type=${type}&play_mode=${playMode}`,
        {
          observe: "response",
          responseType: "blob",
        }
      )
      .subscribe(
        (response: any) => {
          console.log("response", response);
          const blob = new Blob([response.body], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          // take the name from the response header
          const contentDisposition = response.headers.get(
            "Content-Disposition"
          );
          const fileName = contentDisposition
            .split(";")[1]
            .split("=")[1]
            .trim();

          importedSaveAs(blob, fileName);
        },
        (e) => {
          console.log(e);
        }
      );
  }

  enrollFromEmailInvitation(token: string) {
    return this.http.post(`${this.backendApi}/api/event-invitation/enroll`, {
      token,
    });
  }

  addMembers(members: any, eventId: number) {
    return this.http.post(
      `${this.backendApi}/api/ctf-event/requests/select-members`,
      {
        event_id: eventId,
        user_ids: members,
      }
    );
  }

  getMyTeam() {
    return this.http.get(`${this.backendApi}/api/group/mine`);
  }

  makeAnnouncement(data: FormData) {
    return this.http.post(
      `${this.backendApi}/api/event/make-announcement`,
      data
    );
  }

  updateDisplayName(
    data: {
      display_name: string;
      target_id: string;
    },
    eventId: number
  ) {
    return this.http.post(
      `${this.backendApi}/api/ctf-event/${eventId}/display-name`,
      data
    );
  }

  getLabStats(eventId: number) {
    return this.http.get(`${this.backendApi}/api/ctf-event-stats/${eventId}`, {
      headers: { ignoreLoadingInterceptor: "true" },
    });
  }
}
