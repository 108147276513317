import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NavBarService {
  /**
   * Subscribers can be notified on loading bar state change
   * Feeders can also change loading bar state using the next method
   */
  loadingSubj: Subject<boolean> = new Subject();
  loading$ = this.loadingSubj.asObservable();
  /**
   * This indicates how many running actions require the loading bar to be displayed
   */
  activeLoadings = 0;

  startLoading() {
    this.loadingSubj.next(true);
  }

  stopLoading() {
    this.loadingSubj.next(false);
  }
}
