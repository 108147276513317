import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { environment } from "@environments/environment";
import { User } from "@interfaces/user";
import { RouterModule } from "@angular/router";

@Component({
  selector: "app-navbar-logos-section",
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: "./navbar-logos-section.component.html",
  styleUrls: ["./navbar-logos-section.component.scss"],
})
export class NavbarLogosSectionComponent {
  @Input() co_branding!: number;
  @Input() user: User | null | undefined = {} as User;

  readonly environment = environment;
  backendAccount = this.environment.backend.account;
}
