import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, filter, take, switchMap } from "rxjs/operators";

import { RedirectService } from "@services/redirect.service";
import { JwtService } from "@services/jwt.service";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    private readonly jwtService: JwtService,
    private readonly redirectService: RedirectService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // FIXME: Set appropriate interfaces

    // Check if the request has the custom header
    if (request.headers.has("Ignore-Error-Interceptor")) {
      // Clone the request without the custom header
      const clonedRequest = request.clone({
        headers: request.headers.delete("Ignore-Error-Interceptor"),
      });
      return next.handle(clonedRequest); // Proceed without error handling
    }
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.error) {
          if (environment.settings.debuging) {
            console.log("err", err);
          }
          // This long condition is due to the nature of the backend's response
          if ((err as HttpErrorResponse).status === 401) {
            if (
              (err as HttpErrorResponse).error.message ===
                "auth.token_expired" ||
              ((err as HttpErrorResponse).error.errors &&
                (err as HttpErrorResponse).error.errors[0] &&
                (err as HttpErrorResponse).error.errors[0].message ===
                  "errors.auth.token_expired")
            ) {
              console.log(
                "[Http Error Interceptor]%c Refreshing token..",
                "color:orange"
              );
              if (!this.isRefreshing) {
                this.jwtService.refreshJwtSubj.next(true);
                this.isRefreshing = true;
                return this.jwtService.refreshedJwt$.pipe(
                  switchMap((token: string) => {
                    this.isRefreshing = false;
                    if (token) {
                      return next.handle(
                        this.setJwtTokenInHeader(request, token)
                      );
                    } else {
                      this.redirectService.logout("login");
                      return throwError(
                        () => new Error("New JWT token was not provided")
                      );
                    }
                  }),
                  catchError((e) => {
                    console.log("error refresh token");
                    this.redirectService.logout("login");
                    return throwError(() => e);
                  })
                );
              } else {
                console.log("error here : ");
                return this.jwtService.refreshedJwt$.pipe(
                  filter((token) => token != null),
                  take(1),
                  switchMap((jwt) => {
                    return next.handle(this.setJwtTokenInHeader(request, jwt));
                  })
                );
              }
            }

            // auth.token_invalid
            if (
              (err as HttpErrorResponse).error.message ===
                "auth.token_invalid" ||
              ((err as HttpErrorResponse).error.errors &&
                (err as HttpErrorResponse).error.errors[0] &&
                (err as HttpErrorResponse).error.errors[0].message ===
                  "errors.auth.token_invalid")
            ) {
              console.log("Token invalid", JSON.stringify(err));
              this.redirectService.logout("login");
              return throwError(() => err);
            }
          } else if (
            (err as HttpErrorResponse).status === 403 &&
            (err as HttpErrorResponse).error.message ===
              "token.unavailable_or_expired"
          ) {
            this.redirectService.logout("login");
            return throwError(() => err);
          }
          if (
            (err as HttpErrorResponse).status === 401 &&
            (err as HttpErrorResponse).error.errors[0].message ===
              "errors.auth.token_blocked"
          ) {
            if (environment.settings.autoLogout) {
              this.redirectService.logout("login");
            }
          }
        }
        return throwError(() => err);
      })
    );
  }

  private setJwtTokenInHeader(request: HttpRequest<any>, token: string) {
    // FIXME: Set appropriate interfaces
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
