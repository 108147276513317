/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";

// import * as Sentry from "@sentry/angular";
// import { environment } from "@environments/environment";

// Sentry.init({
//   environment: environment?.monitoring?.environment,
//   dsn: environment?.monitoring?.sentryDSN,

//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],

//   tracesSampleRate: 1.0,

//   tracePropagationTargets: environment?.monitoring?.tracePropagationTargets,

//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
