<div class="flex items-center justify-center w-full relative">
  <img
    [ngStyle]="{
      filter: 'blur(6px)'
    }"
    class="absolute top-[60px]"
    src="assets/images/event-placeholder.png"
  />
  <div
    class="bg-black-tertiary fixed top-[30%] rounded p-8 inline-block z-10 w-[500px]"
  >
    <div *ngIf="!errorMessage" class="flex items-center mb-6">
      <!-- <img src="assets/icons/event-default.svg" class="w-[40px] mr-4" /> -->
      <div
        i18n="@@eventInvitation"
        class="text-3xl text-white-primary font-chakra font-bold"
      >
        Invitation to Event
      </div>
    </div>

    <p
      *ngIf="!errorMessage"
      i18n="@@verifyinAttendance"
      class="text-gray-primary"
    >
      Verifying your attendance to the event. Redirecting to the event page in a
      few seconds..
    </p>

    <div *ngIf="errorMessage" class="flex justify-center">
      <div>
        <div class="flex flex-col items-center mb-4">
          <div
            class="p-[9px] bg-red-primary bg-opacity-[0.07] rounded-full w-12 h-12 flex items-center justify-center mb-3"
          >
            <img class="w-[50px]" src="/assets/icons/close-red.svg" />
          </div>
          <div
            i18n="@@accessDenied"
            *ngIf="accessDenied"
            class="text-red-primary font-chakra font-bold"
          >
            Access Denied
          </div>
          <div
            i18n="@@errorEventInvitation"
            *ngIf="!accessDenied"
            class="text-red-primary font-chakra font-bold"
          >
            Error
          </div>
        </div>
        <p class="text-gray-primary">{{ errorMessage }}</p>
        <div class="flex justify-center w-full">
          <button
            routerlink="/dashboard"
            class="w-[170px] py-[10px] mt-6 border-[#282E38] hover:bg-black-tertiary brighter text-sm leading-5 rounded-lg outline-none cursor-pointer border text-white-primary"
          >
            <span i18n="@@gotToDashboard">Go to dashboard</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
