import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { RolesService } from "@services/roles.service";

@Injectable({
  providedIn: "root",
})
export class RoleGuardService implements CanActivate {
  constructor(
    private readonly rolesService: RolesService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isAllowed = async () => {
      const isAllowed = await this.rolesService.isAllowed(route.data["roles"]);

      if (isAllowed) return true;
      return this.router.parseUrl("/404");
    };
    return isAllowed();
  }
}
