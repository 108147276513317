import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { SvgLoader } from "angular-svg-icon";

@Injectable({
  providedIn: "root",
})
export class SvgHttpLoader extends SvgLoader {
  constructor() {
    super();
  }

  getSvg(url: string): Observable<string> {
    return new Observable<string>((observer) => {
      fetch(url, { mode: "no-cors" })
        .then((response: Response) =>
          response
            .text()
            .then((data) => {
              observer.next(data);
              observer.complete();
            })
            .catch((err) => observer.error(err))
        )
        .catch((err) => observer.error(err));
    });
  }
}
