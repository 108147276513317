const env = (window as any)["env"];

export const environment: any = {
  name: "prod",
  production: true,
  settings: {
    appName: "SecDojo",
    emulatorAllowed: false,
    debuging: true,
    redirect: true,
    verbose: true,
    autoLogout: true,
    isSecDojo: true,
    logoPath: "assets/icons/Logo.svg",
    logoMobilePath: "assets/icons/logo-mobile.svg",
    disablePurchases: false,
  },
  websites: {
    discord: "https://discord.gg/hwJ3aeKdQU",
  },
  analytics: {
    mixPanelToken: "bdd023eda5929e6ea8f075a75bfe433b", // Mixpanel Token
    clarityProjectId: "exo794qq54", // Clarity Project ID
    googleAnalyticsTrackingCode: "G-6MGYY9TP9G", // Google Analytics Tracking Code
  },
  monitoring: {
    // Data Source Name. In dev and preprod leave empty
    sentryDSN: "https://6b5648757eacf3618208762731dcfabe@sentry.sec-dojo.com/5",

    // Trace Propagation Targets
    // In dev and preprod leave empty
    // In production set to backend URL regexes
    // For example, for Account: [/^https:\/\/bo-account\.sec-dojo\.com/]
    tracePropagationTargets: [/^https:\/\/bo-cyberlab\.sec-dojo\.com/],

    // In dev and preprod leave empty. In production set to "saas-production"
    environment: "saas-production",
  },
  externalLibs: {
    tinyMCEapiKey: "gewca3t802g4nh9hrd2q638kb4kg1je33rspr1iwqmemsfeo",
  },
  frontend: {
    account: "https://account.sec-dojo.com",
    cyberlab: "https://cyberlab.sec-dojo.com",
    cyberlearn: "https://cyberlearn.sec-dojo.com",
    cyberwar: "https://cyberwar.sec-dojo.com",
    secdojo: "https://www.sec-dojo.com",
    support: "https://support.sec-dojo.com",
    cyberawareness: "https://cyber-awareness.sec-dojo.com",
  },
  backend: {
    cyberlab: "https://bo-cyberlab.sec-dojo.com",
    account: "https://bo-account.sec-dojo.com",
  },
};

if (env) {
  // Override config options if they exist in the environment
  for (const key in env) {
    if (Object.prototype.hasOwnProperty.call(env, key)) {
      environment[key] = env[key];
    }
  }
}
