import { Component, Input, OnInit, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { WalletService } from "@services/wallet.service";
import { Subscription, UserWallet, WalletsData } from "@interfaces/wallet";
import { User, UserData } from "@interfaces/user";
import { AuthService } from "@services/auth/auth.service";
import { SubscriptionMenuComponent } from "@components/subscription-menu/subscription-menu.component";
import { DialogService } from "@ngneat/dialog";
import { environment } from "@environments/environment";
import { BehaviorSubject, Subject } from "rxjs";
import { SubscriptionMenuDropdownComponent } from "@components/subscription-menu-dropdown/subscription-menu-dropdown.component";

@Component({
  selector: "app-wallet-nav",
  standalone: true,
  imports: [
    CommonModule,
    AngularSvgIconModule,
    SubscriptionMenuDropdownComponent,
  ],
  templateUrl: "./wallet-nav.component.html",
  styleUrls: ["./wallet-nav.component.scss"],
})
export class WalletNavComponent implements OnInit {
  private dialog: DialogService = inject(DialogService);
  // wallets: UserWallet[] = [];
  // user!: User | undefined;
  // readonly environment = environment;
  // isWalletsLoaded = false;

  // freeWallet: any = {};
  // personalWallet: any = {};
  // subscriptionWallet: any = {};
  readonly environment = environment;

  wallets!: WalletsData;
  user!: User | undefined;

  isWalletsLoaded = false;

  freeWallet: any = null;
  personalWallet: any = null;
  @Input() showPremiumButton!: boolean;

  subscriptionWallet: any = null;

  planText = $localize`:@@elevateYourTrainingLevel:Elevate your training with expert scenarios and top-tier security!`;

  subscription!: Subscription;
  isSubscribed = false;
  buttonText = $localize`:@@upgradeNow:Upgrade Now!`;
  walletFeatures!: any;
  companyWallet: any = null;

  constructor(
    private walletService: WalletService,
    private authService: AuthService
  ) {
    this.authService.currentUser$.subscribe((userData: any) => {
      this.user = userData?.user;
      if (this.user) {
        this.subscription = userData.user.subscription;
      }
    });
  }
  ngOnInit(): void {
    if (environment.production) {
      this.getWallets();
    }
  }

  getWallets() {
    this.walletService.getWallets().subscribe((data: WalletsData) => {
      this.isWalletsLoaded = true;
      if (data) {
        const wallets = data.wallets;
        this.subscription = data.subscription;

        if (wallets.DEFAULT) {
          this.personalWallet = {
            type: "personal",
            balance: wallets.DEFAULT.balance,
          };
        }

        if (wallets.FREE) {
          this.freeWallet = {
            type: "fidelity",
            balance: wallets.FREE.balance,
          };
        }

        if (wallets.SUBSCRIPTION) {
          this.subscriptionWallet = {
            type: "subscription",
            balance: wallets.SUBSCRIPTION.balance,
          };
        }
        if (wallets.COMPANY) {
          this.companyWallet = {
            type: "company",
            balance: wallets.COMPANY.balance,
          };
        }
        if (this.subscription) {
          if (this.subscription.has_subscription) {
            this.isSubscribed = true;
            this.walletFeatures = JSON.parse(
              this.subscription.subscription_details.features
            );
            this.planText =
              $localize`:@@youAreSubscribed:You are subscribed to the ` +
              this.subscription.subscription_details.plan +
              $localize`:@@enjoyYourTraining: plan. Enjoy your training!`;
            this.buttonText = $localize`:@@viewPlan:View Plan`;
          }
        }
      }
    });
  }

  showSubscriptionMenu() {
    const dialogRef = this.dialog.open(SubscriptionMenuComponent, {
      width: "300px",
      windowClass: "navigationMenuPopup",
    });

    dialogRef.afterClosed$.subscribe((result) => {
      if (result) {
        window.open(environment.frontend.account + "/subscribe", "_blank");
      }
    });
  }
}
