import { Injectable } from "@angular/core";
import { ModulesAccess } from "@interfaces/company";
import { BehaviorSubject, skip, skipWhile, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ModuleAccessService {
  public moduleAccess$: BehaviorSubject<ModulesAccess | null> =
    new BehaviorSubject<ModulesAccess | null>(null);

  moduleAccess!: ModulesAccess;
  constructor() {
    this.moduleAccess$.subscribe((moduleAccess: ModulesAccess | null) => {
      moduleAccess !== null ? (this.moduleAccess = moduleAccess) : null;
    });
  }

  isAllowed(moduleToAccess: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.moduleAccess$
        .pipe(
          skipWhile((userAccessModules: ModulesAccess | null) => {
            return userAccessModules === null;
          })
        )
        .subscribe((userAccessModules) => {
          if (userAccessModules !== null) {
            resolve(this.checkModuleAccess(moduleToAccess, userAccessModules));
          }
        });
    });
  }

  private checkModuleAccess(
    moduleToAccess: string,
    userAccessModules: ModulesAccess
  ) {
    switch (moduleToAccess) {
      case "certifications":
        return userAccessModules.cyber_certification_access === 1;
      case "events":
        return userAccessModules.cyberevent_access === 1;

      case "hiring":
        return userAccessModules.cyber_hiring_access === 1;

      case "cyberlearn":
        return userAccessModules.cyberlearn_access === 1;

      case "cyberlab":
        return userAccessModules.cyberlab_access === 1;

      case "cyberawarness":
        return userAccessModules.cyber_awareness_access === 1;

      default:
        return false;
    }
  }
}
