import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";

import { GenericResp } from "@interfaces/generic-resp";
import { catchError, map, Observable, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class OnboardingService {
  constructor(private http: HttpClient) {}
  updateOnboarding(): Observable<GenericResp<any>> {
    return this.http
      .post<GenericResp<any>>(
        `${environment.backend.cyberlab}/api/onboarding/first-login`,
        {},
        { headers: { ignoreLoadingInterceptor: "true" } }
      )
      .pipe(
        catchError((error: any) => {
          return throwError(() => new Error(error));
        })
      );
  }
}
