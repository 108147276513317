import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { GenericResp } from "@interfaces/generic-resp";
import { UserWallet, WalletsData } from "@interfaces/wallet";
import { DialogService } from "@ngneat/dialog";
import { BehaviorSubject, map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WalletService {
  private readonly backendApi = environment.backend.cyberlab;
  public walletSubject = new BehaviorSubject<UserWallet[]>([]);
  constructor(public http: HttpClient) {}

  getUserWallets(): Observable<UserWallet[]> {
    return this.http
      .get<GenericResp<UserWallet[]>>(`${this.backendApi}/api/User/wallets`)
      .pipe(map((resp: GenericResp<UserWallet[]>) => resp.data));
  }

  getWallets(): Observable<WalletsData> {
    return this.http
      .get<GenericResp<WalletsData>>(`${this.backendApi}/api/wallet`)
      .pipe(map((resp: GenericResp<WalletsData>) => resp.data));
  }
}
