import { NgModule } from "@angular/core";
import { RouterModule, ROUTES } from "@angular/router";
import { EventInvitationComponent } from "@components/event-invitation/event-invitation.component";
import { DeactivatedGuardGuard } from "@guards/deactivated-guard.guard";
import { AuthGuardService } from "@services/auth/auth-guard.service";
import { ModuleAcessGuardService } from "@services/auth/module-access.service";
// import { ModuleAccessGuardService } from "@services/auth/module-access-guard.service";
import { RoleGuardService } from "@services/auth/role-guard.service";
import { MobileRedirectGuardService } from "@services/mobile-redirect-guard.service";
import { RolesService } from "@services/roles.service";

function routesFactory(rolesService: RolesService) {
  return [
    // Route empty path to dashboard
    {
      path: "",
      redirectTo: "dashboard",
      pathMatch: "full",
    },
    {
      path: "dashboard",
      canActivate: [AuthGuardService, MobileRedirectGuardService],
      loadChildren: async () =>
        (await rolesService.rolesPromise()).includes("company-manager")
          ? import("./pages/company-manager/dashboard/dashboard.module").then(
              (m) => m.DashboardModule
            )
          : import("./pages/dashboard/dashboard.module").then(
              (m) => m.DashboardModule
            ),
    },
    {
      path: "ranking",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/ranking/ranking.module").then((m) => m.RankingModule),
      data: {
        roles: ["user", "company-member"],
      },
    },
    {
      path: "404",
      loadChildren: () =>
        import("./pages/four-o-four/four-o-four.module").then(
          (m) => m.FourOFourModule
        ),
    },
    {
      path: "labs",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/labs/labs.module").then((m) => m.LabsModule),
      data: {
        roles: ["user", "company-member"],
      },
    },
    {
      path: "labs/type/:type",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/category-labs/category-labs.module").then(
          (m) => m.CategoryLabsModule
        ),
      data: {
        roles: ["user", "company-member"],
      },
    },

    {
      path: "labs/scenario/:type/:lab_id",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: async () =>
        (await rolesService.rolesPromise()).includes("company-manager")
          ? import("./pages/company-manager/lab-detail/lab-detail.module").then(
              (m) => m.LabDetailModule
            )
          : import("./pages/lab-scenario/lab-scenario.module").then(
              (m) => m.LabScenarioModule
            ),
      data: {
        roles: ["user", "company-member", "company-manager"],
      },
    },
    {
      path: "labs/active",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/active-labs/active-labs.module").then(
          (m) => m.ActiveLabsModule
        ),
      data: {
        roles: ["user", "company-member"],
      },
    },

    {
      path: "labs/instance/:instance_id",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/lab-instance/lab-instance.module").then(
          (m) => m.LabInstanceModule
        ),
      data: {
        roles: ["user", "company-member"],
      },
    },

    {
      path: "certifications",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        ModuleAcessGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/certifications/certifications.module").then(
          (m) => m.CertificationsModule
        ),
      data: {
        roles: ["company-manager", "user", "company-member"],
        module: "certificationAccess",
        // access: "certifications",
      },
    },
    {
      path: "certifications/:id",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        ModuleAcessGuardService,
        MobileRedirectGuardService,
      ],

      loadChildren: () =>
        import("./pages/certification-detail/certification-detail.module").then(
          (m) => m.CertificationDetailModule
        ),
      data: {
        roles: ["company-manager", "user", "company-member"],
        module: "certificationAccess",
      },
    },
    {
      path: "user/certifications",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        ModuleAcessGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/own-certifications/own-certifications.module").then(
          (m) => m.OwnCertificationsModule
        ),
      data: {
        roles: ["user", "company-member"],
        module: "certificationAccess",
      },
    },

    {
      path: "company/certifications-members",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        ModuleAcessGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import(
          "./pages/company-manager/certifications-member/certifications-member.module"
        ).then((m) => m.CertificationsMemberModule),
      data: {
        roles: ["company-manager"],
        module: "certificationAccess",
      },
    },

    {
      path: "labs/history",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/labs-history/labs-history.module").then(
          (m) => m.LabsHistoryModule
        ),
      data: {
        roles: ["user", "company-member"],
      },
    },
    {
      path: "company/labs",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/company-manager/labs/labs.module").then(
          (m) => m.LabsModule
        ),
      data: {
        roles: ["company-manager"],
      },
    },
    {
      path: "company/labs/active",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/company-manager/active-labs/active-labs.module").then(
          (m) => m.ActiveLabsModule
        ),
      data: {
        roles: ["company-manager"],
      },
    },
    {
      path: "company/labs/history",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/company-manager/labs-history/labs-history.module").then(
          (m) => m.LabsHistoryModule
        ),
      data: {
        roles: ["company-manager"],
      },
    },
    {
      path: "company/labs/purchased",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import(
          "./pages/company-manager/lab-purchased/lab-purchased.module"
        ).then((m) => m.LabPurchasedModule),
      data: {
        roles: ["company-manager"],
      },
    },
    {
      path: "company/labs/top-purchased",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import(
          "./pages/company-manager/top-purchased-labs/top-purchased-labs.module"
        ).then((m) => m.TopPurchasedLabsModule),
      data: {
        roles: ["company-manager"],
      },
    },
    {
      path: "company/labs/free",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/company-manager/free-labs/free-labs.module").then(
          (m) => m.FreeLabsModule
        ),
      data: {
        roles: ["user"],
      },
    },
    {
      path: "company/labs/popular",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/company-manager/popular/popular.module").then(
          (m) => m.PopularModule
        ),
      data: {
        roles: ["company-manager", "company-member"],
      },
    },

    {
      path: "company/labs/requests",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/company-manager/lab-requests/lab-requests.module").then(
          (m) => m.LabRequestsModule
        ),
      data: {
        roles: ["company-manager"],
      },
    },
    {
      path: "company/labs/type/:type",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import(
          "./pages/company-manager/category-labs/category-labs.module"
        ).then((m) => m.CategoryLabsModule),
      data: {
        roles: ["company-manager"],
      },
    },
    {
      path: "company/:type/category/:category/statistics",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import(
          "./pages/company-manager/category-statistics/category-statistics.module"
        ).then((m) => m.CategoryStatisticsModule),
      data: {
        roles: ["company-manager"],
      },
    },
    {
      path: "company/:type/category/:category/ranking",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/company-manager/category-ranking/ranking.module").then(
          (m) => m.RankingModule
        ),
      data: {
        roles: ["company-manager"],
      },
    },
    {
      path: "company/scenario/:type/:lab_id",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: async () =>
        (await rolesService.rolesPromise()).includes("company-manager")
          ? import("./pages/company-manager/lab-detail/lab-detail.module").then(
              (m) => m.LabDetailModule
            )
          : import("./pages/lab-scenario/lab-scenario.module").then(
              (m) => m.LabScenarioModule
            ),
      data: {
        roles: ["user", "company-member", "company-manager"],
      },
    },
    {
      path: "cyber-hiring/candidates",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        // ModuleAccessGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/cyber-hiring/candidates/candidates.module").then(
          (m) => m.CandidatesModule
        ),
      data: {
        roles: ["cyber-hirer"],
        // access: "hiring",
      },
    },

    {
      path: "cyber-hiring/jobs",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        ModuleAcessGuardService,
        // ModuleAccessGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/cyber-hiring/jobs/jobs.module").then(
          (m) => m.JobsModule
        ),
      data: {
        roles: ["cyber-hirer", "user", "company-member", "company-manager"],
        module: "hiringAccess",
        // access: "hiring",
      },
    },
    {
      path: "cyber-hiring/hiring-request/:id",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        // ModuleAccessGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import(
          "./pages/cyber-hiring/hiring-request/hiring-request.module"
        ).then((m) => m.HiringRequestModule),
      data: {
        roles: ["cyber-hirer", "user", "company-member"],
        // access: "hiring",
      },
    },
    {
      path: "cyber-hiring/job-application/:id",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        // ModuleAccessGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import(
          "./pages/cyber-hiring/job-application/job-application.module"
        ).then((m) => m.JobApplicationModule),
      data: {
        roles: ["cyber-hirer", "user", "company-member"],
        // access: "hiring",
      },
    },
    {
      path: "cyber-hiring/post-a-job",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        // ModuleAccessGuardService,
        MobileRedirectGuardService,
      ],
      pathMatch: "full",

      loadChildren: () =>
        import("./pages/cyber-hiring/post-a-job/post-a-job.module").then(
          (m) => m.PostAJobModule
        ),
      data: {
        roles: ["cyber-hirer"],
        // access: "hiring",
      },
    },
    {
      path: "cyber-hiring/jobs/:id",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        ModuleAcessGuardService,
        // ModuleAccessGuardService,
        MobileRedirectGuardService,
      ],
      pathMatch: "full",

      loadChildren: () =>
        import("./pages/cyber-hiring/job-detail/job-detail.module").then(
          (m) => m.JobDetailModule
        ),
      data: {
        roles: ["cyber-hirer", "user", "company-member", "company-manager"],
        module: "hiringAccess",
        // access: "hiring",
      },
    },
    {
      path: "cyber-hiring/job-offer/:id/edit",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        // ModuleAccessGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/cyber-hiring/job-edit/job-edit.module").then(
          (m) => m.JobEditModule
        ),
      data: {
        roles: ["cyber-hirer", "company-manager"],
        // access: "hiring",
      },
    },

    {
      path: "cyber-hiring/hiring/settings",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        // ModuleAccessGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import(
          "./pages/cyber-hiring/hirer/settings/profile-settings.module"
        ).then((m) => m.ProfileSettingsModule),
      data: {
        roles: ["cyber-hirer"],
        // access: "hiring",
      },
    },
    {
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        // ModuleAccessGuardService,
        MobileRedirectGuardService,
      ],
      path: "statistics/mitre/:type",
      loadChildren: () =>
        import("./pages/mitre-stats/mitre-stats.module").then(
          (m) => m.MitreStatsModule
        ),

      data: {
        roles: ["company-member", "company-manager"],
      },
    },
    {
      path: "user/profile/:candidate_id",
      canActivate: [AuthGuardService, MobileRedirectGuardService],

      loadChildren: () =>
        import(
          "./pages/cyber-hiring/hirer/candidate-profile/candidate-profile.module"
        ).then((m) => m.CandidateProfileModule),
      // data: {
      //   roles: ["cyber-hirer"],
      // },
    },
    {
      path: "cyber-hiring/profile/edit",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        ModuleAcessGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import(
          "./pages/cyber-hiring/candidate/settings/profile-settings.module"
        ).then((m) => m.ProfileSettingsModule),
      data: {
        roles: ["user", "company-member"],
        module: "hiringAccess",
      },
    },
    {
      path: "cyber-hiring/profile/overview",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/cyber-hiring/candidate/profile/profile.module").then(
          (m) => m.ProfileModule
        ),
      data: {
        roles: ["user", "company-member"],
      },
    },
    {
      path: "company/ranking",
      canActivate: [
        AuthGuardService,
        RoleGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/company-manager/ranking/ranking.module").then(
          (m) => m.RankingModule
        ),
      data: {
        roles: ["company-manager"],
      },
    },
    {
      path: "events",
      canActivate: [
        AuthGuardService,
        ModuleAcessGuardService,
        // ModuleAccessGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/events/events.module").then((m) => m.EventsModule),
      data: {
        roles: ["company-member", "company-manager", "user", "super-admin"],
        module: "eventAccess",
        // access: "events",
      },
    },
    {
      path: "events/create",
      canActivate: [
        AuthGuardService,
        ModuleAcessGuardService,
        // ModuleAccessGuardService,
        MobileRedirectGuardService,
      ],
      loadChildren: () =>
        import("./pages/event-create/event-create.module").then(
          (m) => m.EventCreateModule
        ),
      data: {
        module: "eventAccess",
        // access: "events",
      },
    },
    {
      path: "events/:id",

      canActivate: [
        AuthGuardService,
        ModuleAcessGuardService,
        // ModuleAccessGuardService,
        MobileRedirectGuardService,
      ],

      loadChildren: () =>
        import("./pages/event-detail/event-detail.module").then(
          (m) => m.EventDetailModule
        ),
      data: {
        roles: ["company-member", "company-manager", "user", "super-admin"],
        module: "eventAccess",
        // access: "events",
      },
    },

    {
      path: "events/:id/edit",
      canActivate: [
        AuthGuardService,
        ModuleAcessGuardService,
        // ModuleAccessGuardService,
        MobileRedirectGuardService,
      ],

      loadChildren: () =>
        import("./pages/event-edit/event-edit.module").then(
          (m) => m.EventEditModule
        ),
      data: {
        roles: ["company-manager", "super-admin"],
        module: "eventAccess",
        // access: "events",
      },
    },
    {
      path: "event-invitation",
      canActivate: [
        AuthGuardService,
        ModuleAcessGuardService,
        // ModuleAccessGuardService,
        MobileRedirectGuardService,
      ],

      component: EventInvitationComponent,
      data: {
        roles: ["company-manager", "super-admin"],
        module: "eventAccess",
      },
    },
    {
      path: "assistance",
      canActivate: [AuthGuardService],

      loadChildren: () =>
        import("./pages/assistance/assistance.module").then(
          (m) => m.AssistanceModule
        ),
    },
    {
      path: "mobile-redirect",
      loadChildren: () =>
        import("./pages/mobile-redirect/mobile-redirect.module").then(
          (m) => m.MobileRedirectModule
        ),
    },
    {
      path: "assistance/sessions/:id",
      canActivate: [AuthGuardService],

      loadChildren: () =>
        import("./pages/assistance-detail/assistance-detail.module").then(
          (m) => m.AssistanceDetailModule
        ),
    },
    {
      path: "support",
      canActivate: [AuthGuardService],

      loadChildren: () =>
        import("./pages/support/support.module").then((m) => m.SupportModule),
    },
    // {
    //   path: "calendar",
    //   canActivate: [AuthGuardService],
    //   loadChildren: () =>
    //     import("./pages/my-calendar/my-calendar.module").then(
    //       (m) => m.MyCalendarModule
    //     ),

    //   data: {
    //     roles: ["company-member", "company-manager", "user", "super-admin"],
    //   },
    // },
    {
      path: "settings",
      loadChildren: () =>
        import("./pages/settings/settings.module").then(
          (m) => m.SettingsModule
        ),
    },
    {
      path: "debug",
      loadChildren: () =>
        import("./pages/debug/debug.module").then((m) => m.DebugModule),
    },

    // Route everything else to 404
    {
      path: "**",
      redirectTo: "404",
    },
  ];
}

@NgModule({
  imports: [RouterModule.forRoot([])],
  providers: [
    {
      provide: ROUTES,
      useFactory: routesFactory,
      multi: true,
      deps: [RolesService],
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
