<div class="bg-black-tertiary p-4">
  <div class="text-white-primary text-sm mb-4">
    {{ planText }}
  </div>
  <div class="w-full text-center">
    <a
      [href]="accountUrl + '/subscribe'"
      (click)="upgrade()"
      appRipple
      class="bg-[#f2dba2] cursor-pointer w-full inline-block text-[#282E38] font-chakra py-2 px-1 font-semibold"
    >
      {{ buttonText }}
    </a>
  </div>
</div>
