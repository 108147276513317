import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CtfEventsService } from "@services/ctf-events.service";

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: "app-event-invitation",
  templateUrl: "./event-invitation.component.html",
  styleUrls: ["./event-invitation.component.scss"],
})
export class EventInvitationComponent implements OnInit {
  token = "";
  errorMessage = "";
  loading = true;
  accessDenied = false;
  constructor(
    private readonly eventService: CtfEventsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams["event_token"];
    console.log("this.token", this.token);
    this.eventService.enrollFromEmailInvitation(this.token).subscribe(
      (resp: any) => {
        if (resp.data.event_id) {
          this.router.navigate(["/events", resp.data.event_id]);
        }
      },
      (err: any) => {
        this.loading = false;
        const errorMap: any = {
          "Event finished": $localize`:@@eventFinishedError:The event has already finished`,
          "Invalid token": $localize`:@@invalidTokenError:There was an error with your invitation link. Please contact the event organizer.`,
          "Event is full": $localize`:@@eventFullError:The event has reached its maximum capacity. Please contact the event organizer.`,
          "Invalid email": $localize`:@@invalidEmailError:There email address associated with your account is invalid. Please throuthly review the email invitation and try again.`,
        };

        if (errorMap[err.error.message]) {
          this.accessDenied = true;
        }

        this.errorMessage =
          errorMap[err.error.message] ||
          $localize`:@@defaultError:An error occurred. Please try again later.`;
      }
    );
  }
}
