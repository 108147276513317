<div
  class="w-[350px] md:w-[471px] min-h-[484px] relative shadow-card modal-content bg-black-secondary border rounded border-black-primary"
>
  <svg-icon [src]="'/assets/icons/hexagon-1.svg'" class="absolute"></svg-icon>
  <ng-container *ngIf="onboardingAccount.length">
    <div *ngFor="let step of onboardingAccount">
      <ng-container
        *ngIf="activeStep === step.id && onboardingActive === 'account'"
      >
        <div
          class="background-account-{{
            step.id
          }} mt-6 relative z-20 flex justify-center flex-col items-center"
        >
          <div class="absolute top-[190px] text-center px-10">
            <span
              *ngIf="step.id > 1"
              class="text-xs block mb-1 capitalize text-[#637387]"
              >welcome, <strong>{{ user?.name }}</strong></span
            >
            <h2
              class="font-chakra text-xl text-white-primary font-bold leading-8 mb-4"
            >
              {{ step.title }} {{ step.id === 1 ? user?.name : "" }}
            </h2>

            <p
              class="text-center font-inter text-sm text-gray-fourth leading-5 mb-9"
            >
              {{ step.description }}
            </p>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <div *ngFor="let step of onboardingCyberlab">
    <ng-container
      *ngIf="activeStep === step.id && onboardingActive === 'cyberlab'"
    >
      <div
        class="background-{{
          step.id
        }}  relative z-20 flex justify-center flex-col items-center mb-6"
        [ngClass]="{
          'top-[93px]': step.id === 1,
          'top-[123px]': step.id === 2,
          'top-[100px]': step.id === 3,
          'top-[78px]': step.id === 4
        }"
      ></div>
      <div>
        <div class="absolute top-[230px] text-center px-10">
          <span class="text-xs block mb-1 capitalize text-[#637387]"
            >welcome, <strong>{{ user?.name }}</strong></span
          >
          <h2
            class="font-chakra text-xl text-white-primary font-bold leading-8 mb-2"
          >
            {{ step.title }}
          </h2>

          <p
            class="text-center font-inter text-sm text-gray-fourth leading-5 mb-2"
          >
            {{ step.description }}
          </p>

          <!-- <ul class="dots mb-6">
            <li
              (click)="goToStep(step.id)"
              *ngFor="let step of onboardingCyberlab"
              [ngClass]="{ active: activeStep === step.id }"
            ></li>
          </ul> -->

          <!-- <div class="flex items-center justify-center">
            <button
              *ngIf="step.id > 1"
              (click)="previousStep()"
              class="px-10 py-2 mr-2 text-sm leading-5 border rounded-sm outline-none border-black-secondary bg-black-secondary text-white-primary"
            >
              <span> Back</span>
            </button>
            <button
              (click)="nextStep()"
              class="px-10 py-2 text-sm leading-5 rounded-sm outline-none bg-green-secondary text-white-primary"
            >
              <span>
                {{
                  step.id !== onboardingCyberlab.length ? "Next" : "Close"
                }}</span
              >
            </button>
          </div> -->
        </div>
      </div>
    </ng-container>
  </div>

  <div
    class="absolute bottom-6 flex flex-col w-full justify-center items-center"
  >
    <ul class="dots mb-6">
      <!-- (click)="goToStep(step)" -->
      <li
        *ngFor="let step of dotList"
        [ngClass]="{
          active:
            (activeStep === step && onboardingActive === 'account') ||
            (activeStep + 3 === step &&
              onboardingActive === 'cyberlab' &&
              onboardingAccount.length) ||
            (activeStep === step &&
              onboardingActive === 'cyberlab' &&
              !onboardingAccount.length)
        }"
      ></li>
    </ul>

    <div class="flex items-center justify-center">
      <button
        *ngIf="
          activeStep > 1 ||
          (onboardingAccount.length &&
            activeStep === 1 &&
            onboardingActive === 'cyberlab')
        "
        (click)="previousStep()"
        class="px-10 py-2 mr-2 text-sm leading-5 border rounded-sm outline-none border-black-secondary bg-black-secondary text-white-primary"
      >
        <span> Back</span>
      </button>
      <button
        (click)="nextStep()"
        class="px-10 py-2 text-sm leading-5 rounded-sm outline-none bg-green-secondary text-white-primary"
      >
        <span>
          {{
            activeStep !== onboardingCyberlab.length ? "Next" : "Close"
          }}</span
        >
      </button>
    </div>
  </div>

  <!-- End Step 2 -->
</div>
