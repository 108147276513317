<!-- header start -->

<div class="w-full top-0 absolute">
  <div
    class="flex items-center justify-between w-full px-6 py-3 bg-green-secondary"
  >
    <nav class="flex items-center justify-between gap-8 nav-left-wrapper">
      <div class="flex items-center justify-between">
        <div
          class="mr-3 cursor-pointer sidebar-menu-toggler"
          (click)="toggleMobileSideBar()"
        >
          <span>
            <img
              src="/assets/icons/menu-responsive.svg"
              alt="menu-responsive"
            />
          </span>
        </div>
        <app-navbar-logos-section
          [user]="user"
          [co_branding]="co_branding"
        ></app-navbar-logos-section>
      </div>
    </nav>
    <div class="justify-end" *ngIf="user">
      <div class="flex items-center gap-4">
        <!-- <button
          *ngIf="
            showPremiumButton &&
            !user?.subscription &&
            !environment.settings.disablePurchases
          "
          (click)="showSubscriptionMenu()"
          class="flex items-center mr-4 justify-between px-2 py-2 rounded cursor-pointer bg-black-transparent text-white-primary"
        >
          <svg-icon
            [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
            src="/assets/icons/crown-outline-1.svg"
            class="mx-1"
          ></svg-icon>
          <span
            class="text-[#fff] font-chakra font-semibold text-xs tracking-wide mx-1"
            >Upgrade</span
          >
        </button> -->
        <ng-container *ngIf="userRoles.length">
          <app-wallet-nav
            [showPremiumButton]="showPremiumButton"
            class="hidden min-[805px]:flex"
          ></app-wallet-nav>
        </ng-container>

        <div>
          <app-dropdown-profile-menu [user]="user"></app-dropdown-profile-menu>
        </div>

        <div class="relative">
          <div
            *ngIf="notificationNumber > 0"
            class="absolute min-w-[15px] z-10 min-h-[15px] top-[-4px] right-[4px] p-[2px] flex justify-center items-center rounded-full bg-red-primary text-white-primary"
          >
            <span class="text-xxs">
              {{ notificationNumber }}
            </span>
          </div>
          <button
            appRipple
            (click)="showNotificationPopup()"
            class="flex items-center justify-between relative bg-black-transparent border-none px-2 py-2 rounded cursor-pointer"
          >
            <!-- class="flex rounded-full relative px-2 py-1.5 justify-between text-white-primary cursor-pointer items-center" -->
            <img src="/assets/icons/notification.svg" alt="notifications" />
          </button>
        </div>

        <!-- <button
          (click)="showNavigationMenu()"
          class="flex rounded mr-2 px-2 py-1.5 justify-between text-white-primary cursor-pointer items-center"
        >
          <img src="/assets/icons/navigation-menu.svg" alt="navigation-menu" />
        </button> -->
      </div>
    </div>
  </div>
  <div class="fixed z-10 w-full" [hidden]="!isLoading">
    <div class="progress">
      <div class="indeterminate"></div>
    </div>
  </div>
</div>
<!-- header End -->

<div class="flex mt-[56px]">
  <!-- sidebar Start-->
  <aside
    class="h-screen py-4 overflow-hidden min-h-[300px] border-r sidebar bg-black-secondary border-black-primary relative"
  >
    <header class="flex items-center px-4 pt-2 pb-6 pr-2">
      <a
        class="w-full p-2 flex items-center hover:bg-black-tertiary rounded cursor-pointer"
        (click)="document.location.href = frontendAccount + '/profile'"
      >
        <img
          *ngIf="isUserLoaded && user?.avatar_id"
          class="h-12 w-14"
          [src]="
            user?.avatar_id
              ? backendAccount + '/avatar/' + user?.avatar_id
              : 'assets/icons/avatar.svg'
          "
          alt="avatar"
        />
        <div class="flex flex-col ml-3">
          <h3
            class="mb-1 text-xs font-normal leading-4 font-inter text-white-primary"
          >
            {{ user?.firstname }} {{ user?.lastname }}
          </h3>
          <h5
            class="text-xs font-normal leading-4 font-inter text-gray-primary"
          >
            {{ user?.name }}
          </h5>
        </div>
      </a>
    </header>
    <hr class="border border-black-primary" />
    <ng-scrollbar class="scrollbar sidebar-container">
      <nav class="px-4 relative">
        <ul class="mt-4">
          <span *ngFor="let sidebarLink of sidebarLinks; let i = index">
            <a
              (click)="sidebarLink.callback()"
              *ngIf="sidebarLink.header === 'Logout'"
              class="flex items-center p-3 mb-1 text-sm rounded cursor-pointer hover:bg-black-tertiary"
            >
              <img
                class="w-5 h-5 mr-4"
                [src]="sidebarLink.img"
                [alt]="sidebarLink.header"
              />

              <span class="text-sm font-normal leading-5 text-white-primary">{{
                sidebarLink.header
              }}</span>
            </a>

            <a
              *ngIf="!sidebarLink.items && sidebarLink.header !== 'Logout'"
              routerLinkActive="active-link"
              [routerLink]="sidebarLink.link"
              class="flex items-center p-3 mb-1 text-sm rounded cursor-pointer hover:bg-black-tertiary"
            >
              <img
                class="w-5 h-5 mr-4"
                [src]="sidebarLink.img"
                [alt]="sidebarLink.header"
              />

              <span class="text-sm font-normal leading-5 text-white-primary">{{
                sidebarLink.header
              }}</span>
            </a>

            <!-- <li
              (click)="sidebarLink.callback ? sidebarLink.callback() : ''"
              *ngIf="!sidebarLink.items"
              routerLinkActive="active-link"
              [routerLink]="sidebarLink.link"
              class="flex items-center p-3 mb-1 text-sm rounded cursor-pointer hover:bg-black-tertiary"
            >
              <img
                class="w-5 h-5 mr-4"
                [src]="sidebarLink.img"
                [alt]="sidebarLink.header"
              />

              <span class="text-sm font-normal leading-5 text-white-primary">{{
                sidebarLink.header
              }}</span>
            </li> -->
            <li
              *ngIf="sidebarLink.items"
              class="flex items-center justify-between mb-1 text-sm font-normal leading-5 rounded"
            >
              <div class="flex flex-col items-start w-full">
                <div
                  (click)="toggleAccordion(i)"
                  class="flex items-center w-full p-3 rounded cursor-pointer hover:bg-black-tertiary"
                >
                  <img
                    class="w-[20px] mr-4"
                    src="{{ sidebarLink.img }}"
                    alt=""
                  />
                  <div class="flex items-center justify-between w-full">
                    <!-- [ngClass]="{ 'mb-6': activeAccordionItemIndex === 2 && isOpen }" -->
                    <span
                      class="text-sm font-normal leading-5 rounded text-white-primary"
                      >{{ sidebarLink.header }}</span
                    >
                    <div>
                      <svg-icon
                        src="/assets/icons/chevron-down.svg"
                        [ngClass]="
                          activeAccordionItemIndex === i && isOpen
                            ? 'icon-up'
                            : 'icon-down'
                        "
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="w-full"
                  [ngStyle]="{
                    maxHeight:
                      activeAccordionItemIndex === i && isOpen
                        ? sidebarLink.items.length * 40 + 10 + 'px'
                        : 0,
                    overflow: 'hidden',
                    transition: 'max-height 0.2s ease'
                  }"
                >
                  <div class="ml-3">
                    <ng-container
                      *ngFor="let item of sidebarLink.items; let j = index"
                    >
                      <div class="mb-0 last:h-[35px] last:flex w-full">
                        <div class="flex items-center w-full">
                          <div class="flex h-full ml-2 mr-2 flex-start">
                            <div
                              [ngStyle]="{
                                height:
                                  j === sidebarLink.items.length - 1
                                    ? 0
                                    : '35px'
                              }"
                              class="w-[1.5px] bg-green-primary"
                            ></div>
                            <div class="curved-line"></div>
                          </div>

                          <a
                            class="w-full px-2 py-1 rounded cursor-pointer hover:bg-black-tertiary"
                            routerLinkActive="active-link"
                            [routerLinkActiveOptions]="{ exact: true }"
                            [routerLink]="[item?.link]"
                          >
                            <span
                              class="text-xs font-normal leading-5 text-white-primary"
                              >{{ item.name }}</span
                            >
                          </a>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <!-- accordion controllers -->
              </div>
            </li>
          </span>
        </ul>
      </nav>
    </ng-scrollbar>
    <div
      class="absolute bottom-[65px] text-gray-primary text-[10px] flex items-center pl-[10px]"
      *ngIf="!environment.settings.isSecDojo"
    >
      Powered by
      <img
        class="w-[60px] ml-[5px]"
        style="filter: brightness(0.8)"
        src="/assets/icons/Logo.svg"
        alt="secdojo"
      />
    </div>

    <div
      (click)="scrollToTop()"
      *ngIf="showScrollButton"
      class="fixed bottom-[20px] right-[20px] flex ninja-button items-center px-4 py-2 rounded-[50%] h-[45px] w-[45px] outline-none bg-green-secondary text-white-primary cursor-pointer z-10 flex items-center justify-center"
    >
      <div class="relative flex items-center justify-center">
        <div class="cloud cloud1">
          <img class="w-[40px]" src="assets/icons/wind.svg" />
        </div>
        <div class="cloud cloud2">
          <img class="w-[25px]" src="assets/icons/wind.svg" />
        </div>
      </div>
      <svg-icon
        [svgStyle]="{
          'width.px': 25,
          'height.px': 25
        }"
        class="white-icon"
        src="assets/icons/scroll-up.svg"
      ></svg-icon>
    </div>
    <!-- <div class="ninja-container fixed w-[200px] h-[70px]"></div> -->
  </aside>
  <!-- sidebar End-->

  <ng-scrollbar #ngScrollbar class="app-container scrollbar">
    <ng-template #modalWrapper></ng-template>
    <router-outlet></router-outlet>
  </ng-scrollbar>
</div>

<!-- sidebar mobile-->

<aside
  [ngStyle]="{
    width: isMobileSidebarMode ? '278px' : '0',
    transition: 'width .2s ease-in-out'
  }"
  class="w-[278px] z-20 absolute top-0 bottom-0 mobile-sidebar h-screen py-4 overflow-hidden min-h-[300px] border-r bg-black-secondary border-black-primary"
>
  <header class="flex items-center px-4 pt-2 pb-6 pr-2">
    <img
      *ngIf="isUserLoaded"
      class="h-12 w-14"
      [src]="
        user?.avatar_id
          ? backendAccount + '/avatar/' + user?.avatar_id
          : 'assets/icons/avatar.svg'
      "
      alt="avatar"
    />
    <div class="flex flex-col ml-3">
      <h3
        class="mb-1 text-xs font-normal leading-4 font-inter text-white-primary"
      >
        {{ user?.firstname }} {{ user?.lastname }}
      </h3>
      <h5 class="text-xs font-normal leading-4 font-inter text-gray-primary">
        {{ user?.name }}
      </h5>
    </div>
  </header>
  <hr class="border border-black-primary" />
  <ng-scrollbar class="scrollbar sidebar-container">
    <nav class="px-4">
      <ul class="mt-4">
        <span *ngFor="let sidebarLink of sidebarLinks; let i = index">
          <!-- <li
            (click)="
              sidebarLink.callback
                ? sidebarLink.callback()
                : mobileLinkClicked(sidebarLink.link)
            "
            *ngIf="!sidebarLink.items"
            routerLinkActive="active-link"
            class="flex items-center p-3 mb-1 text-sm rounded cursor-pointer hover:bg-black-tertiary"
          >
            <img
              class="w-5 h-5 mr-4"
              [src]="sidebarLink.img"
              [alt]="sidebarLink.header"
            />

            <span class="text-sm font-normal leading-5 text-white-primary">{{
              sidebarLink.header
            }}</span>
          </li> -->

          <li
            (click)="sidebarLink.callback()"
            *ngIf="sidebarLink.header === 'Logout'"
            class="flex items-center p-3 mb-1 text-sm rounded cursor-pointer hover:bg-black-tertiary"
          >
            <img
              class="w-5 h-5 mr-4"
              [src]="sidebarLink.img"
              [alt]="sidebarLink.header"
            />

            <span class="text-sm font-normal leading-5 text-white-primary">{{
              sidebarLink.header
            }}</span>
          </li>

          <a
            *ngIf="!sidebarLink.items && sidebarLink.header !== 'Logout'"
            routerLinkActive="active-link"
            [routerLink]="sidebarLink.link"
            class="flex items-center p-3 mb-1 text-sm rounded cursor-pointer hover:bg-black-tertiary"
          >
            <img
              class="w-5 h-5 mr-4"
              [src]="sidebarLink.img"
              [alt]="sidebarLink.header"
            />

            <span class="text-sm font-normal leading-5 text-white-primary">{{
              sidebarLink.header
            }}</span>
          </a>
          <li
            *ngIf="sidebarLink.items"
            class="flex items-center justify-between mb-1 text-sm font-normal leading-5 rounded"
          >
            <div class="flex flex-col items-start w-full">
              <div
                (click)="toggleAccordion(i)"
                class="flex items-center w-full p-3 rounded cursor-pointer hover:bg-black-tertiary"
              >
                <img class="w-[20px] mr-4" src="{{ sidebarLink.img }}" alt="" />
                <div class="flex items-center justify-between w-full">
                  <!-- [ngClass]="{ 'mb-6': activeAccordionItemIndex === 2 && isOpen }" -->
                  <span
                    class="text-sm font-normal leading-5 rounded text-white-primary"
                    >{{ sidebarLink.header }}</span
                  >
                  <div>
                    <svg-icon
                      src="/assets/icons/chevron-down.svg"
                      [ngClass]="
                        activeAccordionItemIndex === i && isOpen
                          ? 'icon-up'
                          : 'icon-down'
                      "
                    />
                  </div>
                </div>
              </div>
              <div
                class="w-full"
                [ngStyle]="{
                  maxHeight:
                    activeAccordionItemIndex === i && isOpen
                      ? sidebarLink.items.length * 40 + 10 + 'px'
                      : 0,
                  overflow: 'hidden',
                  transition: 'max-height 0.2s ease'
                }"
              >
                <div class="ml-3">
                  <ng-container
                    *ngFor="let item of sidebarLink.items; let j = index"
                  >
                    <div class="mb-0 last:h-[35px] last:flex w-full">
                      <div class="flex items-center w-full">
                        <div class="flex h-full ml-2 mr-2 flex-start">
                          <div
                            [ngStyle]="{
                              height:
                                j === sidebarLink.items.length - 1 ? 0 : '35px'
                            }"
                            class="w-[1.5px] bg-green-primary"
                          ></div>
                          <div class="curved-line"></div>
                        </div>

                        <a
                          (click)="mobileLinkClicked(item?.link)"
                          class="w-full px-2 py-1 rounded cursor-pointer hover:bg-black-tertiary"
                          routerLinkActive="active-link"
                          [routerLink]="item.link"
                          [routerLinkActiveOptions]="{ exact: true }"
                        >
                          <!-- [routerLink]="[item?.link]" -->
                          <span
                            class="text-xs font-normal leading-5 text-white-primary"
                            >{{ item.name }}</span
                          >
                        </a>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <!-- accordion controllers -->
            </div>
          </li>
        </span>
      </ul>
    </nav>
  </ng-scrollbar>
</aside>

<div
  *ngIf="isMobileSidebarMode"
  class="absolute top-0 bottom-0 z-10 w-full h-screen backdrop-sidebar bg-black-primary bg-opacity-60"
  (click)="toggleMobileSideBar()"
></div>

<app-toast></app-toast>
