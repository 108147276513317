import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";

import { AuthService } from "./auth.service";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { RedirectService } from "@services/redirect.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private redirectService: RedirectService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.isLoggedIn) {
      return true;
    }
    if (environment.settings.redirect) {
      this.redirectService.logout("login");
    }
    return false;
  }
}
