import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { PaginationResp } from "@interfaces/generic-pagination-resp";
import { GenericResp } from "@interfaces/generic-resp";
import { GeneralNotif, Notification } from "@interfaces/notification";
import { map, pipe, Subject } from "rxjs";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  notificationSubject = new Subject<number>();
  notification$ = this.notificationSubject.asObservable();

  private readonly backendApi = environment.backend.cyberlab;

  constructor(private readonly http: HttpClient) {}

  getSpecialNotifications(
    read: null | boolean,
    page: number
  ): Observable<GenericResp<any>> {
    return this.http.get<GenericResp<any>>(
      `${this.backendApi}/api/v2/notifications?perPage=10&read=${read}&page=${page}`,
      {
        headers: {
          ignoreLoadingInterceptor: "true", // Skip HTTP Loading Interceptor
          ignoreError: "true",
        },
      }
    );
  }

  readBulkNotification(ids: any): Observable<GenericResp<any>> {
    return this.http.post<GenericResp<any>>(
      `${this.backendApi}/api/notifications/read`,
      ids,
      { headers: { ignoreLoadingInterceptor: "true" } } // Skip HTTP Loading Interceptor
    );
  }

  readNotification(id: number): Observable<GenericResp<any>> {
    return this.http.post<GenericResp<any>>(
      `${this.backendApi}/api/cyberlab/notification/read`,
      {
        id: id,
      },
      { headers: { ignoreLoadingInterceptor: "true" } } // Skip HTTP Loading Interceptor
    );
  }

  readSharedNotification(id: number): Observable<GenericResp<any>> {
    return this.http.post<GenericResp<any>>(
      `${this.backendApi}/api/notification/shared/read`,
      {
        id: id,
      },
      { headers: { ignoreLoadingInterceptor: "true" } } // Skip HTTP Loading Interceptor
    );
  }

  getNotifications(
    dissmissed: boolean,
    page: number,
    perPage = 5
  ): Observable<PaginationResp<GeneralNotif[]>> {
    return this.http
      .post<GenericResp<PaginationResp<GeneralNotif[]>>>(
        `${this.backendApi}/api/cyberlab/notifications?page=${page}`,
        {
          dissmissed: dissmissed,
          perPage: perPage,
        },
        { headers: { ignoreLoadingInterceptor: "true" } }
      )
      .pipe(
        map(
          (response: GenericResp<PaginationResp<GeneralNotif[]>>) =>
            response.data
        )
      );
  }

  getNotificationNumber(): Observable<GenericResp<any>> {
    return this.http.get<GenericResp<any>>(
      `${this.backendApi}/api/v2/notification-number`,
      { headers: { ignoreLoadingInterceptor: "true", ignoreError: "true" } } // Skip HTTP Loading & Error Interceptor
    );
  }

  updateLastNotificationCheck(): Observable<GenericResp<any>> {
    return this.http.post<GenericResp<any>>(
      `${this.backendApi}/api/v2/notifications/last-check`,
      {},
      { headers: { ignoreLoadingInterceptor: "true", ignoreError: "true" } } // Skip HTTP Loading Interceptor
    );
  }
}
