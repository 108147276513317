import { Component, inject, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogRef } from "@ngneat/dialog";
import { OnboardingService } from "@services/onboarding.service";
import { OnboardingModalContent } from "@interfaces/onboarding";
import { AngularSvgIconModule } from "angular-svg-icon";
import { Subscription } from "rxjs";
import { User } from "@interfaces/user";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  group,
  query,
  animateChild,
} from "@angular/animations";

@Component({
  selector: "app-onboarding-modals",
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  templateUrl: "./onboarding-modals.component.html",
  styleUrls: ["./onboarding-modals.component.scss"],
  // animations: [
  //   trigger("slideAnimation", [
  //     state(
  //       "inactive",
  //       style({
  //         opacity: 0,
  //         position: "absolute",
  //         transform: "translateX(-100%)", // Slide out to the left
  //         width: "0", // Ensuring it does not take up any space
  //         height: "0", // Ensuring it does not take up any space
  //         overflow: "hidden", // Prevents any spillover during transition
  //       }),
  //     ),
  //     state(
  //       "active",
  //       style({
  //         opacity: 1,
  //         position: "relative",
  //         transform: "translateX(0)",
  //         width: "*", // or specific size if necessary
  //         height: "*", // or specific size if necessary
  //       }),
  //     ),
  //     transition("inactive => active", [
  //       group([
  //         query("@*", animateChild(), { optional: true }),
  //         animate(
  //           "300ms ease-out",
  //           style({
  //             opacity: 1,
  //             position: "relative",
  //             transform: "translateX(0)",
  //             width: "*", // Restores to original width
  //             height: "*", // Restores to original height
  //           }),
  //         ),
  //       ]),
  //     ]),
  //     transition("active => inactive", [
  //       group([
  //         query("@*", animateChild(), { optional: true }),
  //         animate(
  //           "300ms ease-in",
  //           style({
  //             opacity: 0,
  //             position: "absolute",
  //             transform: "translateX(-100%)",
  //             width: "0", // Collapse to no width
  //             height: "0", // Collapse to no height
  //             overflow: "hidden",
  //           }),
  //         ),
  //       ]),
  //     ]),
  //   ]),
  // ],
})
export class OnboardingModalsComponent implements OnInit {
  ref: DialogRef<any> = inject(DialogRef);
  user!: User | null;
  onboardingAccount!: OnboardingModalContent[];
  onboardingCyberlab!: OnboardingModalContent[];
  activeStep = 1;
  updateOnboarding!: Subscription;
  onboardingActive = "account";
  dotList!: number[];
  private lastIndex = 0;

  // getDirection(index: number) {
  //   const direction = index > this.lastIndex ? "right" : "left";
  //   this.lastIndex = index;
  //   return direction;
  // }
  ngOnInit() {
    this.user = this.ref.data.user;
    this.onboardingAccount = this.ref.data.onboardingAccount;
    this.onboardingCyberlab = this.ref.data.onboardingCyberlab;
    this.onboardingActive = this.ref.data.onboardingActive;
    this.dotList = Array.from(
      {
        length:
          this.ref.data.onboardingAccount.length +
          this.ref.data.onboardingCyberlab.length,
      },
      (_, index) => index + 1
    );
  }

  nextStep() {
    this.activeStep += 1;
    if (this.activeStep - 1 === this.onboardingCyberlab.length) {
      this.ref.close(true);
    }
    if (
      this.activeStep - 1 === this.onboardingAccount.length &&
      this.onboardingActive === "account"
    ) {
      this.activeStep = 1;
      this.onboardingActive = "cyberlab";
    }
  }
  goToStep(step: number) {
    this.activeStep = step;
  }
  previousStep() {
    this.activeStep--;
    if (this.activeStep === 0 && this.onboardingActive === "cyberlab") {
      this.activeStep = this.onboardingAccount.length;
      this.onboardingActive = "account";
    }
  }
}
