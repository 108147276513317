import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { RippleDirective } from "@directives/ripple.directive";

import { DialogService, DialogRef } from "@ngneat/dialog";
import { AngularSvgIconModule } from "angular-svg-icon";

interface Data {
  title: string;
  description: string;
  cancelText: string;
  confirmText: string;
  neutral?: boolean;
  icon?: string;
}
@Component({
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, RippleDirective],
  selector: "app-action-danger-modal",
  templateUrl: "./action-danger-modal.component.html",
  styleUrls: ["./action-danger-modal.component.scss"],
})
export class ActionDangerModalComponent {
  ref: DialogRef<any> = inject(DialogRef);
  // constructor() {}
  get neutral() {
    return this.ref.data?.neutral;
  }

  get important() {
    return this.ref.data?.important;
  }

  get icon() {
    if (this.ref.data.icon) {
      return this.ref.data.icon;
    }
    return null;
  }
  get title() {
    if (!this.ref.data) return "";
    return this.ref.data.title;
  }
  get description() {
    if (!this.ref.data) return "";
    return this.ref.data.description;
  }
  get cancelText() {
    if (!this.ref.data) return "";
    return this.ref.data.cancelText;
  }
  get confirmText() {
    if (!this.ref.data) return "";
    return this.ref.data.confirmText;
  }
}
