import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { environment } from "@environments/environment";

@Component({
  selector: "app-navigation-menu",
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  templateUrl: "./navigation-menu.component.html",
  styleUrls: ["./navigation-menu.component.scss"],
})
export class NavigationMenuComponent {
  readonly cyberlearnUrl = environment.frontend.cyberlearn;
  readonly cyberlabUrl = environment.frontend.cyberlab;
  readonly accountUrl = environment.frontend.account;
  readonly cyberwarUrl = environment.frontend.cyberwar;
  readonly discordUrl = environment.websites.discord;
}
