import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class JwtService {
  private jwtPrivate = "";

  refreshJwtSubj = new BehaviorSubject<boolean>(false);
  readonly refreshJwt$: Observable<boolean> =
    this.refreshJwtSubj.asObservable();

  readonly refreshedJwtSubj = new ReplaySubject<string>();
  readonly refreshedJwt$: Observable<string> =
    this.refreshedJwtSubj.asObservable();

  readonly jwtSubj = new BehaviorSubject<string>("");
  readonly jwt$: Observable<string> = this.jwtSubj.asObservable();

  set jwt(token: string) {
    this.jwtSubj.next(token);
    this.jwtPrivate = token;
  }

  get jwt(): string {
    return this.jwtPrivate;
  }
}
