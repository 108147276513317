import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { RedirectService } from "@services/redirect.service";

@Injectable({
  providedIn: "root",
})
export class AccessErrorInterceptor implements HttpInterceptor {
  constructor(private readonly redirectService: RedirectService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          if (error.error && error.error.message) {
            const errorMessage = error.error.message;
            if (errorMessage === "cyberlab.access_denied") {
              this.redirectService.logout("login");
            }
          }
        }
        throw error;
      })
    );
  }
}
