<div class="dropdown">
  <button
    appRipple
    *ngIf="isSubscribed && subscriptionWallet"
    class="flex items-center z-10 gap-2 dropdown-subscribe-button text-white-primary bg-black-transparent border-none cursor-pointer px-3 py-1.5 rounded"
    (click)="toggleDropdown()"
  >
    <svg-icon
      src="assets/icons/crown-outline-1.svg"
      [svgStyle]="{ 'height.px': 20, 'width.px': 20 }"
      class="pointer-events-none"
    ></svg-icon>
    <span class="font-chakra text-sm pointer-events-none">{{
      subscriptionWallet.balance ?? 0
    }}</span>
  </button>

  <button
    appRipple
    *ngIf="!isSubscribed"
    class="flex items-center z-10 gap-2 dropdown-subscribe-button text-white-primary bg-black-transparent border-none cursor-pointer px-3 py-1.5 rounded"
    (click)="toggleDropdown()"
  >
    <svg-icon
      [svgStyle]="{ 'height.px': 20, 'width.px': 20 }"
      src="/assets/icons/crown-outline-1.svg"
      class="pointer-events-none"
    ></svg-icon>
    <span
      class="text-[#fff] font-chakra font-semibold text-xs tracking-wide mx-1 pointer-events-none"
      >Upgrade</span
    >
  </button>

  <div
    class="dropdown-content shadow-card border border-black-primary rounded-lg bg-black-secondary"
    [ngClass]="{ show: isDropdownOpen }"
    #dropdownContent
  >
    <div class="p-4">
      <div class="text-white-primary text-sm mb-4">
        {{ planText }}
      </div>
      <div
        *ngIf="isSubscribed && subscriptionWallet"
        class="flex items-center mb-2"
      >
        <span
          class="text-white-primary w-[250px] text-sm font-inter"
          i18n="@@amountOfCoinsLeft"
          >Amount of coins left</span
        >
        <div class="flex items-center gap-2">
          <span class="text-white-primary text-sm font-chakra">{{
            subscriptionWallet.balance
          }}</span>
          <svg-icon
            class="ml-1 yellow-icon"
            [svgStyle]="{
              'height.px': 15,
              'width.px': 15
            }"
            src="assets/icons/dojo-coin.svg"
          ></svg-icon>
        </div>
      </div>
      <div
        *ngIf="isSubscribed && subscriptionWallet"
        class="flex items-center mb-4"
      >
        <span
          class="text-white-primary w-[250px] text-sm font-inter"
          i18n="@@monthlyWalletBalance"
          >Monthly wallet balance:</span
        >
        <div class="flex items-center gap-2">
          <span class="text-white-primary text-sm font-chakra">{{
            walletFeatures.wallet_balance
          }}</span>
          <svg-icon
            class="ml-1 yellow-icon"
            [svgStyle]="{
              'height.px': 15,
              'width.px': 15
            }"
            src="assets/icons/dojo-coin.svg"
          ></svg-icon>
        </div>
      </div>

      <div class="w-full text-center">
        <a
          *ngIf="showPremiumButton"
          [href]="accountUrl + '/subscribe'"
          (click)="upgrade()"
          appRipple
          class="bg-[#f2dba2] rounded cursor-pointer w-full inline-block text-black-2 font-chakra py-2 px-1 font-semibold"
        >
          {{ buttonText }}
        </a>
      </div>
    </div>
  </div>
</div>
