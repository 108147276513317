import { CommonModule } from "@angular/common";
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { environment } from "@environments/environment";
import { User } from "@interfaces/user";
import { AuthService } from "@services/auth/auth.service";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "app-subscription-menu-dropdown",
  standalone: true,
  imports: [AngularSvgIconModule, FormsModule, CommonModule],
  templateUrl: "./subscription-menu-dropdown.component.html",
  styleUrls: ["./subscription-menu-dropdown.component.scss"],
})
export class SubscriptionMenuDropdownComponent {
  @Input() subscriptionWallet!: any;
  @Input() showPremiumButton!: boolean;
  @Input() walletFeatures!: any;
  @ViewChild("dropdownContent") dropdownContent!: ElementRef;
  isDropdownOpen = false;
  @Input()
  planText = $localize`:@@elevateYourTrainingLevel:Elevate your training with expert scenarios and top-tier security!`;

  @Input() isSubscribed = false;
  @Input() buttonText = $localize`:@@upgradeNow:Upgrade Now!`;
  public readonly accountUrl = environment.frontend.account;

  // constructor(private readonly authService: AuthService) {}
  // ngOnInit(): void {

  // }
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    console.log("dropdown show", this.isDropdownOpen);
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    const isButtonClicked = clickedElement.classList.contains(
      "dropdown-subscribe-button"
    );

    if (
      !isButtonClicked &&
      !this.dropdownContent.nativeElement.contains(clickedElement)
    ) {
      this.isDropdownOpen = false;
      console.log("dropdown show click outside", this.isDropdownOpen);
    }
  }
  upgrade() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}
