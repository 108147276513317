<div *ngIf="user" class="dropdown">
  <button
    class="flex items-center gap-2 dropdown-button text-white-primary bg-black-transparent border-none cursor-pointer pl-2 pr-2.5 py-1.5 rounded"
    (click)="toggleDropdown()"
  >
    <img
      class="pointer-events-none"
      [src]="
        user.avatar_id
          ? backendAccount + user.avatar_id
          : '/assets/icons/avatar.svg'
      "
      [ngStyle]="{ 'width.px': 20, 'height.px': 20 }"
    />
    <span
      class="text-xs font-semibold capitalize pointer-events-none text-white-primary font-chakra"
    >
      {{ user.name }}
    </span>
    <div
      *ngIf="user.company"
      class="bg-white pointer-events-none divider hidden min-[620px]:block"
    ></div>
    <span
      *ngIf="user.company"
      class="text-xs font-semibold pointer-events-none font-chakra hidden min-[620px]:block"
      >{{ user.company.name }}</span
    >
  </button>
  <div
    class="dropdown-content"
    [ngClass]="{ show: isDropdownOpen }"
    #dropdownContent
  >
    <div
      class="max-h-[calc(100vh_-_80px)] scrolled-container overflow-auto border border-black-primary rounded-lg bg-white-primary bg-black-secondary"
    >
      <div class="mb-2 border-b border-black-primary p-3">
        <div class="flex items-center">
          <img
            class="ml-1 pointer-events-none"
            [src]="
              user.avatar_id
                ? backendAccount + user.avatar_id
                : 'assets/icons/avatar.svg'
            "
            [ngStyle]="{ 'width.px': 35, 'height.px': 35 }"
          />
          <div class="ml-3">
            <div class="text-sm text-white-primary max-w-[130px] truncate">
              {{ user.name }}
            </div>
            <div class="text-xs text-gray-primary">
              {{ user.firstname }} {{ user.lastname }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <ul class="p-3 border-b border-black-primary">
          <a [href]="profileUrl" class="text-sm text-white-priamry">
            <li
              class="flex items-center p-2 mb-1 transition-all duration-150 ease-in-out rounded hover:bg-black-tertiary text-white-primary"
            >
              <div class="w-[25px] flex justify-center mr-2">
                <svg-icon
                  src="/assets/icons/account.svg"
                  [svgStyle]="{ 'width.px': 20, 'height.px': 25 }"
                  class="green-icon"
                ></svg-icon>
              </div>
              Account
            </li>
          </a>
          <a
            (click)="linkClick($event, modulesAccess.cyberlearnAccess)"
            href="{{ cyberLearnLink }}"
            class="text-sm text-white-priamry"
            [ngClass]="{
              'opacity-40 cursor-default': !modulesAccess.cyberlearnAccess
            }"
          >
            <li
              [ngClass]="{
                'hover:bg-black-tertiary': modulesAccess.cyberlearnAccess
              }"
              class="flex items-center p-2 mb-1 transition-all duration-150 ease-in-out rounded text-white-primary"
            >
              <div class="w-[25px] flex justify-center mr-2">
                <svg-icon
                  src="assets/icons/cyberlearn.svg"
                  [svgStyle]="{ 'width.px': 20, 'height.px': 25 }"
                  class="green-icon"
                ></svg-icon>
              </div>

              CyberLearn
            </li>
          </a>
          <a
            (click)="linkClick($event, modulesAccess.cyberawarenessAccess)"
            href="{{ cyberawarenessLink }}"
            class="text-sm text-white-priamry"
            [ngClass]="{
              'opacity-40 cursor-default': !modulesAccess.cyberawarenessAccess
            }"
          >
            <li
              [ngClass]="{
                'hover:bg-black-tertiary': modulesAccess.cyberawarenessAccess
              }"
              class="flex items-center p-2 mb-1 transition-all duration-150 ease-in-out rounded text-white-primary"
            >
              <div class="w-[25px] flex justify-center mr-2">
                <svg-icon
                  src="/assets/icons/cyberawareness.svg"
                  [svgStyle]="{ 'width.px': 20, 'height.px': 25 }"
                  class="green-icon"
                ></svg-icon>
              </div>
              CyberAwareness
            </li>
          </a>
        </ul>
        <ul
          *ngIf="user.managed_companies"
          class="p-3 border-b border-black-primary"
        >
          <li
            (click)="switchingCompany = !switchingCompany"
            class="cursor-pointer flex justify-between items-center p-2 transition-all duration-150 ease-in-out rounded hover:bg-black-tertiary text-white-primary text-sm text-white-priamry"
          >
            <div class="flex items-center">
              <div class="w-[25px] flex justify-center mr-2">
                <svg-icon
                  src="assets/icons/company.svg"
                  [svgStyle]="{ 'width.px': 18, 'height.px': 18 }"
                  class="green-icon"
                ></svg-icon>
              </div>
              <div>Organizations</div>
            </div>
            <svg-icon
              src="assets/icons/chevron-down.svg"
              [svgStyle]="{ 'width.px': 15, 'height.px': 15 }"
            ></svg-icon>
          </li>
          <div
            #orgBody
            [ngStyle]="{
              'max-height': switchingCompany
                ? orgBody.scrollHeight + 'px'
                : '0',
              overflow: switchingCompany ? 'visible' : 'hidden',
              transition: 'max-height 0.2s ease'
            }"
          >
            <div class="p-2">
              <div
                (click)="switchCompany(org)"
                *ngFor="let org of user.managed_companies"
                class="p-2 hover:bg-black-tertiary rounded cursor-pointer flex items-center w-full text-white-primary text-xs border border-black-tertiary mb-1"
                [ngClass]="{
                  'bg-black-tertiary': org.current
                }"
              >
                {{ org.name }}
              </div>
            </div>
          </div>
        </ul>
        <ul class="p-3 border-b border-black-primary">
          <li
            (click)="isEditingLanguage = !isEditingLanguage"
            class="cursor-pointer flex justify-between items-center p-2 transition-all duration-150 ease-in-out rounded hover:bg-black-tertiary text-white-primary text-sm text-white-priamry"
          >
            <div class="flex items-center">
              <div class="w-[25px] flex justify-center mr-2">
                <svg-icon
                  src="assets/icons/global.svg"
                  [svgStyle]="{ 'width.px': 18, 'height.px': 18 }"
                  class="green-icon"
                ></svg-icon>
              </div>
              <div i18n="@@language">Language</div>
            </div>
            <svg-icon
              src="assets/icons/chevron-down.svg"
              [svgStyle]="{ 'width.px': 15, 'height.px': 15 }"
            ></svg-icon>
          </li>
          <div
            [ngStyle]="{
              'max-height': isEditingLanguage ? '100px' : '0',
              overflow: isEditingLanguage ? 'visible' : 'hidden',
              transition: 'max-height 0.2s ease'
            }"
          >
            <div class="p-2">
              <div
                (click)="
                  user.preferred_language_code !== 'en' && changeLanguage('en')
                "
                class="p-2 hover:bg-black-tertiary rounded cursor-pointer flex items-center w-full text-white-primary text-xs border border-black-tertiary mb-1"
                [ngClass]="{
                  'bg-black-tertiary': user.preferred_language_code === 'en'
                }"
                i18n="@@english"
              >
                <svg-icon
                  src="assets/icons/english.svg"
                  class="mr-2"
                  [svgStyle]="{ 'width.px': 15, 'height.px': 15 }"
                ></svg-icon>
                English
              </div>
              <div
                (click)="
                  user.preferred_language_code !== 'fr' && changeLanguage('fr')
                "
                class="p-2 hover:bg-black-tertiary rounded cursor-pointer flex items-center w-full text-white-primary text-xs border border-black-tertiary"
                [ngClass]="{
                  'bg-black-tertiary': user.preferred_language_code === 'fr'
                }"
                i18n="@@french"
              >
                <svg-icon
                  src="assets/icons/french.svg"
                  class="mr-2"
                  [svgStyle]="{ 'width.px': 15, 'height.px': 15 }"
                ></svg-icon>
                French
              </div>
            </div>
          </div>
        </ul>
        <ul class="p-3">
          <a
            href="{{ discordLink }}"
            target="__blank"
            class="text-sm text-white-priamry"
          >
            <li
              i18n="@@discordServer"
              class="flex items-center p-2 mb-2 transition-all duration-150 ease-in-out rounded hover:bg-black-tertiary text-white-primary"
            >
              <div class="w-[25px] flex justify-center mr-2">
                <svg-icon
                  src="assets/icons/discord.svg"
                  [svgStyle]="{ 'width.px': 18, 'height.px': 18 }"
                  class="green-icon"
                ></svg-icon>
              </div>
              Discord Server
            </li>
          </a>

          <a
            (click)="logoutFromBackend()"
            class="text-sm cursor-pointer text-white-priamry"
          >
            <li
              i18n="@@logout"
              class="flex items-center p-2 transition-all duration-150 ease-in-out rounded hover:bg-black-tertiary text-red-primary"
            >
              <div class="w-[25px] flex justify-center mr-2">
                <svg-icon
                  src="assets/icons/logout-2.svg"
                  [svgStyle]="{ 'width.px': 18, 'height.px': 18 }"
                  class="red-icon"
                ></svg-icon>
              </div>
              Logout
            </li>
          </a>
        </ul>
      </div>
    </div>
  </div>
</div>
