import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from "@angular/common/http";
import { catchError, delay, retry } from "rxjs/operators";
import { of, throwError } from "rxjs";
import { ToastService } from "@services/toast.service";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class BackendErrorInterceptor implements HttpInterceptor {
  apiUrl = environment.backend.cyberlab;
  errorMap: any = {
    "auth.permission.denied": $localize`:@@auth.permission.denied:Permission denied`,
    "User data not found": $localize`:@@user.data.not.found:User data not found`,
    "Team score not found": $localize`:@@team.score.not.found:Team score not found`,
    "Invalid lab id": $localize`:@@invalid.lab.id:Invalid lab id`,
    "Invalid user ID": $localize`:@@invalid.user.id:Invalid user ID`,
    "user.not_found": $localize`:@@user.not.found:User not found`,
    "user.not_authorized": $localize`:@@user.not.authorized:You are not authorized to view this user`,
    "get_active_sessions.invalid_params": $localize`:@@get.active.sessions.invalid.params:Invalid search parameters`,
    "get_domain_category_stats.invalid_params": $localize`:@@get.domain.category.stats.invalid.params:Invalid search parameters`,
    "get_assistance_requests.invalid_params": $localize`:@@get.domain.category.stats.invalid.params:Invalid search parameters`,
    "get_assistance_request.not_found": $localize`:@@get.assistance.request.not.found:Coaching request not found`,
    "get_assisstance_request.unauthorized": $localize`:@@get.assistance.request.unauthorized:You are not authorized to view this coaching request`,
    "manage_assistance_request.unauthorized": $localize`:@@manage.assistance.request.unauthorized:You are not authorized to manage this coaching request`,
    "manage_assistance_request.insufficient_balance": $localize`:@@manage.assistance.request.insufficient_balance:Your organization doesn't have enough balance to manage this coaching request`,
    "manage_assistance_request.billing_failed": $localize`:@@manage.assistance.request.billing_failed:Failed to bill the organization for managing this coaching request`,
    "specify_start_time.not_found": $localize`:@@specify.start.time.not.found:Coaching request not found`,
    "specify_start_time.invalid_input": $localize`:@@specify.start.time.invalid.input:Invalid date`,
    "specify_start_time.unauthorized": $localize`:@@specify.start.time.unauthorized:You are not authorized to specify the start time for this coaching request`,
    "specify_start_time.request_invalid_step": $localize`:@@specify.start.time.request.invalid.step:Invalid request step`,
    "specify_start_time.invalid_time_format": $localize`:@@specify.start.time.invalid.time.format:Invalid time format`,
    "specify_start_time.start_time_too_soon": $localize`:@@specify.start.time.start.time.too.soon:The selected time should be at least 15 minutes from now`,
    "specify_start_time.start_time_not_within_ranges": $localize`:@@specify.start.time.start.time.not.within.ranges:The selected time is not within the available time ranges`,
    "request_availability_ranges.failed": $localize`:@@request.availability.ranges.failed:Failed to set the available time ranges for this coaching request`,
    "review_assistance_session.invalid_user": $localize`:@@review.assistance.session.invalid.user:Invalid user`,
    "reviez_assistance_session.invalid_params": $localize`:@@review.assistance.session.invalid.params:Please fill in all fields`,
    "review_assistance_session.feedback_already_provided": $localize`:@@review.assistance.session.feedback.already.provided:Feedback already provided`,
    "review_assistance_session.invalid_step": $localize`:@@review.assistance.session.invalid.step:The request has been closed`,
    "Permission denied": $localize`:@@permission.denied:Permission denied`,
    "Invalid params": $localize`:@@invalid.params:Invalid parameters`,
    "Company not found": $localize`:@@company.not.found:Organization not found`,
    "Classroom not found": $localize`:@@classroom.not.found:Classroom not found, it may have been deleted`,
    "Objective not found": $localize`:@@objective.not.found:The assignment is not found`,
    "Cannot create objective in the past": $localize`:@@cannot.create.objective.in.the.past:Cannot create an assignment in the past`,
    "Start date cannot be after end date": $localize`:@@start.date.cannot.be.after.end.date:Start date cannot be after end date`,
    "Failed to save objective": $localize`:@@failed.to.save.objective:Failed to save the assignment`,
    "Invalid labs": $localize`:@@invalid.labs:Invalid labs`,
    "Cannot edit assignment after it has started": $localize`:@@cannot.edit.assignment.after.it.has.started:Cannot edit the assignment after it has started`,
    "Not enough balance": $localize`:@@not.enough.balance:Not enough balance`,
    "increaseInstanceDuration.invalid_params": $localize`:@@increaseInstanceDuration.invalid.params:The duration should be at least 1 hour`,
    "increaseInstanceDuration.instance.unauthorized": $localize`:@@increaseInstanceDuration.instance.unauthorized:You are not authorized to increase the duration of these instances`,
    "increaseInstanceDuration.instance.expired": $localize`:@@increaseInstanceDuration.instance.expired:This instance has expired`,
    "company.insufficient_balance": $localize`:@@company.insufficient_balance:Your organization doesn't have enough balance to perform this action`,
    "getCompanySpending.invalid_period": $localize`:@@getCompanySpending.invalid_period:Invalid date period`,
    "company.not.found": $localize`:@@company.not.found:Organization not found`,
    "getCompanyMembersSortedByScore.invalid_params": $localize`:@@getCompanyMembersSortedByScore.invalid_params:Invalid search parameters`,
    "getCompanyMembersRanking.invalid_params": $localize`:@@getCompanyMembersRanking.invalid_params:Invalid search parameters`,
    "getCompanyMemberActivity.invalid_params": $localize`:@@getCompanyMemberActivity.invalid_params:Invalid search parameters`,
    "getCompanyMemberCumulativeActivity.invalid_params": $localize`:@@getCompanyMemberCumulativeActivity.invalid_params:Invalid search parameters`,
    "getCompanyGroupActivity.invalid_params": $localize`:@@getCompanyGroupActivity.invalid_params:Invalid search parameters`,
    "getCompanyGroupCumulativeActivity.validation": $localize`:@@getCompanyGroupCumulativeActivity.validation:Invalid search parameters`,
    "getMembersRankingByCategory.invalid_params": $localize`:@@getMembersRankingByCategory.invalid_params:Invalid search parameters`,
    "Category not found": $localize`:@@category.not.found:Category not found`,
    "category.not.found": $localize`:@@category.not.found:Category not found`,
    "getGeneralStatisticsByCategory.invalid_type": $localize`:@@getGeneralStatisticsByCategory.invalid_type:Invalid search type`,
    "getGeneralCompanyStatisticsByCategory.invalid_type": $localize`:@@getGeneralCompanyStatisticsByCategory.invalid_type:Invalid search type`,
    invalid_search_params: $localize`:@@invalid_search_params:Invalid search parameters`,
    "lab.not.found": $localize`:@@lab.not.found:Lab not found`,
    "create_ctf.permission_denied": $localize`:@@create_ctf.permission_denied:You do not have permission to create an event`,
    "Team members limit is required": $localize`:@@team.members.limit.is.required:Team members limit is required`,
    "create_ctf_event.start_date_invalid": $localize`:@@create_ctf_event.start_date_invalid:Please select a date in the future`,
    "create_ctf_event.free_event_permission_denied": $localize`:@@create_ctf_event.free_event_permission_denied:You do not have permission to create a free event`,
    "Company.NotFound": $localize`:@@company.not.found:Organization not found`,
    "create_ctf_event.failed": $localize`:@@create_ctf_event.failed:Failed to create the event`,
    "No data found in the file": $localize`:@@no.data.found.in.the.file:No data found in the file`,
    "Invalid headings found in the file": $localize`:@@invalid.headings.found.in.the.file:Invalid headings found in the file`,
    "Some users do not exist": $localize`:@@some.users.do.not.exist:Some users were not found`,
    "get_upcoming_events.permission_denied": $localize`:@@get_upcoming_events.permission_denied:You do not have permission to view upcoming events`,
    "view_event_edit_details.permission_denied": $localize`:@@view_event_edit_details.permission_denied:You do not have permission to view this event`,
    "view_event_edit_details.event_not_found": $localize`:@@view_event_edit_details.event_not_found:Event not found`,
    "view_event_edit_details.unauthorized_action": $localize`:@@view_event_edit_details.unauthorized_action:Your organization does not have permission to perform this action`,
    "view_event_details.archived": $localize`:@@view_event_details.archived:This event has been archived`,
    "view_event_details.event_not_found": $localize`:@@view_event_details.event_not_found:Event not found`,
    "view_event_details.permission_denied": $localize`:@@view_event_details.permission_denied:You do not have permission to view this event`,
    "view_event_details.unauthorized": $localize`:@@view_event_details.unauthorized:This event is private`,
    "enroll_in_event.permission_denied": $localize`:@@enroll_in_event.permission_denied:You do not have permission to enroll in this event`,
    "enroll_in_event.must_be_group_leader": $localize`:@@enroll_in_event.must_be_group_leader:You must be a team leader to enroll in this event`,
    "enroll_in_event.balance_insufficient": $localize`:@@enroll_in_event.balance_insufficient:Your balance is insufficient to enroll in this event`,
    "enroll_in_event.company_balance_insufficient": $localize`:@@enroll_in_event.company_balance_insufficient:Insufficient organization balance`,
    "enroll_in_event.event_finished_already": $localize`:@@enroll_in_event.event_finished_already:This event has already ended`,
    "enroll_in_event.group_already_enrolled": $localize`:@@enroll_in_event.group_already_enrolled:Your team is already enrolled in this event`,
    "enroll_in_event.user_already_enrolled": $localize`:@@enroll_in_event.user_already_enrolled:You are already enrolled in this event`,
    "enroll_in_event.limited_event_capacity_reached": $localize`:@@enroll_in_event.limited_event_capacity_reached:This event has reached its capacity`,
    "edit_eventedit_event.permission_denied": $localize`:@@edit_eventedit_event.permission_denied:You do not have permission to edit this event`,
    "edit_event.event_not_found": $localize`:@@edit_event.event_not_found:Event not found`,
    "edit_event.unauthorized_action": $localize`:@@edit_event.unauthorized_action:Your organization does not have permission to edit this event`,
    fields_required: $localize`:@@fields_required:Please fill in all fields`,
    member_limit_required: $localize`:@@member_limit_required:Member limit is required`,
    "edit_event.max_cap_already_achieved": $localize`:@@edit_event.max_cap_already_achieved:Change the member limit to a value higher than the current number of enrolled participants`,
    "edit_event.start_date_invalid": $localize`:@@edit_event.start_date_invalid:Please select a date in the future`,
    "edit_event.lab_sequence_change_not_allowed": $localize`:@@edit_event.lab_sequence_change_not_allowed:You cannot change the lab sequence after the event has started`,
    "edit_event.add_lab.insufficient_balance": $localize`:@@edit_event.add_lab.insufficient_balance:Insufficient balance to add labs`,
    new_duration_is_too_short: $localize`:@@new_duration_is_too_short:The new duration is too short`,
    must_be_free_or_billed_by_company: $localize`:@@must_be_free_or_billed_by_company:This event must be free or billed by the organization`,
    balance_insufficient: $localize`:@@balance_insufficient:Insufficient balance`,
    "archive_event.event_not_found": $localize`:@@archive_event.event_not_found:Event not found`,
    "archive_event.unauthorized_action": $localize`:@@archive_event.unauthorized_action:Your organization does not have permission to archive this event`,
    "archive_event.event_hasnt_ended": $localize`:@@archive_event.event_hasnt_ended:This event has not ended yet`,
    "Event has already started": $localize`:@@Event.has.already.started:This event has already started`,
    team_not_found: $localize`:@@team.not.found:Team not found`,
    enrollment_not_found: $localize`:@@enrollment.not.found:Enrollment not found`,
    company_not_found: $localize`:@@company.not.found:Organization not found`,
    wallet_not_found: $localize`:@@wallet.not.found:Wallet not found`,
    reimbursement_error: $localize`:@@reimbursement.error:Reimbursement error`,
    event_not_found: $localize`:@@event.not.found:Event not found`,
    "Event finished": $localize`:@@Event.finished:This event has already ended`,
    "Event is full": $localize`:@@Event.is.full:This event is full`,
    not_leader: $localize`:@@not.leader:You are not the team leader`,
    "Transaction error": $localize`:@@Transaction.error:Transaction error`,
    "Access denied": $localize`:@@Access.denied:Access denied`,
    Unauthorized: $localize`:@@Unauthorized:Unauthorized`,
    invalid_selectd_members: $localize`:@@invalid_selectd_members:Invalid selected members`,
    "You must be a team leader to enroll in this event.": $localize`:@@You.must.be.a.team.leader.to.enroll.in.this.event:You must be a team leader to enroll in this event`,
    "This event is full.": $localize`:@@This.event.is.full:This event is full`,
    "This event is private.": $localize`:@@This.event.is.private:This event is private`,
    "Insufficient funds.": $localize`:@@Insufficient.funds:Insufficient funds`,
    "Wallet error": $localize`:@@Wallet.error:Error with billing wallet, try again later`,
    "start_lab.instance_limit_reached": $localize`:@@start_lab.instance_limit_reached:You have reached the maximum number of simultaneous instances.`,
    "flag.alreadySubmitted": $localize`:@@submit_flag.alreadySubmitted:Flag already submitted`,
    "approve_lab.insufisent_founds": $localize`:@@balanceUnsuficient:The balance of your organization wallet is insufficient.`,
  };

  constructor(private toastService: ToastService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      // retry({
      //   count: 2,
      //   delay: (error: HttpErrorResponse) => {
      //     switch (error.status) {
      //       case 520:
      //         // retry
      //         return of(null).pipe(delay(100));

      //       default:
      //         throw error;
      //     }
      //   },
      // }),
      catchError((error: HttpErrorResponse) => {
        // If url starts with /assets, then it is a static file, so we don't need to show a toast
        if (
          request.headers.get("ignoreError") ||
          !request.url.includes(this.apiUrl)
        ) {
          throw error;
        }

        let e = error.error.message; // This is the most common error pat

        if (Array.isArray(error.error.errors)) {
          if (
            error.error.errors.length &&
            error.error.errors[0].code === "errors.auth.token_blocked"
          ) {
            throw error;
          }
          if (
            error.error.errors.errors &&
            error.error.errors.errors.length > 0
          ) {
            e = error.error.errors.errors[0];
          }
          if (error.error.errors.message) {
            e = error.error.errors.message;
          }
        } else {
          if (error.error.errors) e = error.error.errors;
        }

        if (this.errorMap[e]) {
          this.toastService.initiate({
            toastClass: "danger-toast",
            toastTitle: this.errorMap[e],
            iconPath: "/assets/icons/close-red.svg",
            show: true,
          });
          throw error;
        }

        let message = "";
        if (error.status === 404) {
          message = "Resource not found";
        }

        if (error.status === 500) {
          message = "Internal server error";
        }

        if (error.status === 400) {
          message = "Bad request";
        }

        if (error.status === 401) {
          message = "Unauthorized";
        }

        if (error.status === 403) {
          message = "Not allowed";
        }

        this.toastService.initiate({
          toastClass: "danger-toast",
          toastTitle: message,
          iconPath: "/assets/icons/close-red.svg",
          show: true,
        });
        return throwError(() => error);
      })
    );
  }
}
