import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  inject,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { User } from "@interfaces/user";
import { Router, RouterModule } from "@angular/router";
import { environment } from "@environments/environment";
import { LanguageService } from "@services/language.service";
import { AuthService } from "@services/auth/auth.service";
import { UtilitiesService } from "@services/utilities.service";
import { RedirectService } from "@services/redirect.service";
import { Company } from "@interfaces/company";
import { ActionDangerModalComponent } from "@components/modals/action-danger-modal/action-danger-modal.component";
import { DialogService } from "@ngneat/dialog";

@Component({
  selector: "app-dropdown-profile-menu",
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, RouterModule],
  templateUrl: "./dropdown-profile-menu.component.html",
  styleUrls: ["./dropdown-profile-menu.component.scss"],
})
export class DropdownProfileMenuComponent implements OnInit {
  private dialog: DialogService = inject(DialogService);

  @Input() user!: User | null;
  @ViewChild("dropdownContent") dropdownContent!: ElementRef;
  isDropdownOpen = false;
  readonly document = document;
  // backendAccount = environment.backend.account;
  readonly environment = environment;
  readonly window = window;
  cyberlabLink = environment.frontend.cyberlab;
  cyberLearnLink = environment.frontend.cyberlearn;
  discordLink = environment.websites.discord;
  cyberawarenessLink = environment.frontend.cyberawareness;

  backendAccount = `${environment.backend.account}/avatar/`;

  profileUrl = `${environment.frontend.account}/profile`;
  subscriptionUrl = `${environment.frontend.account}/subscribe`;

  isEditingLanguage = false;
  switchingCompany = false;

  languagesAvailable: any = [];
  preferredLanguage: any = {};
  isPreferredLanguageLoaded = false;
  isLanguagesLoaded = false;

  modulesAccess = {
    cyberlearnAccess: true,
    cyberlabAccess: true,
    eventAccess: true,
    cyberawarenessAccess: false,
    certificationAccess: false,
    hiringAccess: false,
  };

  constructor(
    private readonly languageService: LanguageService,
    private readonly authService: AuthService,
    private readonly utilitiesService: UtilitiesService,
    private readonly router: Router,
    private readonly redirectService: RedirectService
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getUserLanguages();
  }
  linkClick($event: any, shouldGo: boolean) {
    if (!shouldGo) {
      $event.preventDefault();
    }
  }

  getUser() {
    this.authService.currentUser$.subscribe((userData) => {
      if (userData) {
        const user = userData.user;
        if (user.managed_companies) {
          const managedOrganization = user.managed_companies.filter(
            (company: any) => company.current
          );

          if (managedOrganization.length > 0) {
            user.company = managedOrganization[0];
            user.company_id = managedOrganization[0].id;
          }
        }
        if (user) {
          this.modulesAccess = this.utilitiesService.getModulesAccess(user);
        }
      }
    });
  }

  switchCompany(company: Company) {
    this.authService.switchCompany(company.id).subscribe(() => {
      window.location.reload();
    });
  }
  logoutFromBackend() {
    const description = $localize`:@@yourAboutToLogout:You are about to logout from all platofroms`;
    const dialogRef = this.dialog.open(ActionDangerModalComponent, {
      // data is typed based on the passed generic
      data: {
        title: $localize`:@@AreYouSure:Are you sure?`,
        description: description,
        cancelText: $localize`:@@cancel:Cancel`,
        confirmText: $localize`:@@approve:Confirm`,
        neutral: false,
      },
      size: "400px",
    });
    dialogRef.afterClosed$.subscribe((result) => {
      if (result) {
        this.authService.logoutFromBackend().subscribe({
          next: (data: boolean) => {
            console.log("data", data);
            this.redirectService.logout("login");
          },
        });
      }
    });
  }
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  changeLanguage(code: string) {
    const languageId = this.languagesAvailable.find(
      (language: any) => language.code === code
    ).id;

    this.languageService.setUserPreferredLanguage(languageId).subscribe({
      next: (data: any) => {
        // Get current route:
        if (environment.production) {
          const url = window.location.pathname.split("/").slice(2).join("/");
          window.location.href = `${environment.frontend.cyberlab}/${code}/${url}`;
        } else {
          window.location.reload();
        }
      },
    });
  }

  getUserLanguages() {
    this.languageService.getUserLanguages().subscribe({
      next: (resp: any) => {
        this.languagesAvailable = resp.data.languages;
        this.isLanguagesLoaded = true;

        this.preferredLanguage = resp.data.user_language;
        const userLanguage = this.languagesAvailable.find(
          (language: any) => language.id === this.preferredLanguage.id
        );

        this.isPreferredLanguageLoaded = true;

        if (environment.production) {
          // CHeck if the user is in the right language
          const path = window.location.pathname.split("/").filter((x) => x);
          const url = window.location.pathname.split("/").slice(2).join("/");
          if (userLanguage.code !== path[0]) {
            let destination = `${environment.frontend.cyberlab}/${userLanguage.code}/${url}`;
            // Get query params
            const queryParams = window.location.search;
            destination = `${destination}${queryParams}`;

            window.location.href = destination;
          }
        }
      },
    });
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    const isButtonClicked =
      clickedElement.classList.contains("dropdown-button");

    if (
      !isButtonClicked &&
      !this.dropdownContent.nativeElement.contains(clickedElement)
    ) {
      this.isDropdownOpen = false;
    }
  }
}
