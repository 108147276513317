import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class DeviceService {
  isMobile(): boolean {
    if (environment.settings.emulatorAllowed) {
      return false;
    }
    // Logic to detect mobile devices
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
}
