import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface ToastData {
  toastClass: string;
  toastDescription?: string;
  toastTitle: string;
  iconPath?: string;
  show: boolean;
}

@Injectable({
  providedIn: "root",
})
export class ToastService {
  data: ToastData = {} as ToastData;
  public open = new BehaviorSubject<ToastData>({} as ToastData);

  initiate(data: ToastData) {
    this.data = { ...data, show: true };
    this.open.next(data);
  }

  showSuccess(message: string) {
    this.initiate({
      toastClass: "success-toast",
      toastTitle: message,
      iconPath: "/assets/icons/check.svg",
      show: true,
    });
  }

  showError(message: string) {
    this.initiate({
      toastClass: "danger-toast",
      toastTitle: message,
      iconPath: "/assets/icons/error.svg",
      show: true,
    });
  }

  hide() {
    this.data = { ...this.data, show: false };
    this.open.next(this.data);
  }
}
